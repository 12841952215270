import "./Section2.css"
import section2_img from "../../../images/section2_img.svg"
// import {ArrowCircleRightSharpIcon} from '@mui/icons-material/ArrowCircleRightSharp';
import rightArrow from "../../../images/rightArrow.svg"
import testImg from "../../../images/Fresh Folk Teamwork.png"
const Section2 = () =>{
    return (
        <div className="section2-container">
            <div className="section2-left">
                <img src={testImg} alt="" />
            </div>
            <div className="section2-right">
                <div className="heading">
                    <p>Your Secret Advantage  in the Job Market</p>
                </div>
                <div className="list-container">
                    <div className="list">
                        <div className="arrow"><img src={rightArrow} alt="" width="32px" height="32px"/></div>
                        <div className="list-text"><p style={{fontSize:'24px',color:"white",fontWeight:"400"}}>Fast Track</p>
                        <p>From days to hours, we redefine job hunting.</p> 
                        </div>
                    </div>
                    <div className="list">
                        <div className="arrow"><img src={rightArrow} alt="" width="32px" height="32px"/></div>
                        <div className="list-text">
                            <p style={{fontSize:'24px',color:"white",fontWeight:"400"}}>Personalized Matches</p>
                            <p>Our engine only finds roles that suit you.</p>  
                        </div>
                    </div>
                    <div className="list" >
                        <div className="arrow">
                        <img src={rightArrow} alt="" width="32px" height="32px"/>
                        </div>
                        <div className="list-text">
                            <p style={{fontSize:'24px',color:"white",fontWeight:"400"}}>Level-up</p>
                            <p>By using your current salary as a benchmark, we can ensure you're always aiming higher.</p>  
                        </div>
                    </div>
                    <div className="list">
                        <div className="arrow">
                        <img src={rightArrow} alt="" width="32px" height="32px"/>
                        </div>
                        <div className="list-text">
                        <p style={{fontSize:'24px',color:"white",fontWeight:"400"}}>Try It for Free</p>
                        <p>Sign up today to experience Jobweaver.ai at no cost.</p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section2