import "./Section1.css"
import section1_img from "../../../images/section1_img.svg"
import { context } from "../../../store/context"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"



const Section1 = () => {
    const { loginDetails, setLoginModalOpen } = useContext(context);
    const navigate = useNavigate()


    const handleStartFreeTrail = async () => {
        if (!loginDetails.isLoggedIn) {
              return  setLoginModalOpen(true)
            // return
        } else {
            navigate("/step1")
        }
    }
    return (
        <div className="section1-container">
            <div className="section1-left">
                <p className="heading">
                    Job hunting in hours, <br /> not weeks.
                </p>
                <p className="subtext">
                    Say goodbye to endless scrolling through irrelevant job listings. Discover a smarter, faster way to land your dream job with Jobweaver.ai
                </p>
                <div className="section1-button">
                    <button onClick={handleStartFreeTrail}>{(loginDetails.isLoggedIn) ? "Get Started" : "Get Started for Free"}</button>
                </div>
            </div>
            <div className="section1-right">
                <div className="img-container">
                    <img src={section1_img} alt="" width="100%" height="100%" />
                </div>
            </div>
        </div>
    )
}

export default Section1