import React, { useContext, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';

import countryList from "react-select-country-list";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios"
import TextField from '@mui/material/TextField';
// import InputMask from 'react-input-mask';
import CircularProgress from '@mui/material/CircularProgress';
import Visa from "../../images/visa.svg"
import Mastercard from "../../images/mastercard.svg"
import Amex from "../../images/amex.svg"
import "./payment.css";
import { State } from 'country-state-city';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { context } from "../../store/context";
import Alert from "../../modals/AlertModal/Alert";
import { Desktop, Mobile } from "../responsive/responsive";
import MobileView from "../../components/MobileView/mobile";
import banner from '../../images/payment-banner.png'
import { Box } from "@mui/material";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const REACT_APP_PREMIUM_PLAN_ID = process.env.REACT_APP_PREMIUM_PLAN_ID
const REACT_APP_STRIPE_PRODUCT_KEY = process.env.REACT_APP_STRIPE_PRODUCT_KEY 
const stripePromise = loadStripe(REACT_APP_STRIPE_PRODUCT_KEY);


const CaptureCardDetails = () => {
  const [country, setCountry] = useState("US");
  const [addressState, setAddressState] = useState("CA");
  // const [cardExpiry, setCardExpiry] = useState("");
  const fullNameRef = useRef();
  // const cardNumberRef = useRef();
  const navigate = useNavigate();
  const [cardDetails, setCardDetails] = useState({})
  // const [cardNumber2, setCardNumber2] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const {  setLoginModalOpen, loginDetails, setLoginDetails, setIsUserHavingCard } = useContext(context);
  const user_token = localStorage.getItem("token")
  let params = useLocation().state;



  // ******************************************************************************
  const stripe = useStripe();
  const elements = useElements();
  

  const handlePayment = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
 // Collect other necessary card details from your form
  const cardNumber = elements.getElement('cardNumber');
  const cardExpiry = elements.getElement('cardExpiry');
  const cardCvc = elements.getElement('cardCvc');

  if (!cardExpiry || !cardCvc) {
    setLoading(false);
    return;
  }
  const cardNumberElement = elements.getElement(CardNumberElement)
    const { error, token } = await stripe.createToken( cardNumberElement,{
       name:fullNameRef?.current.value,
       address_line1: cardDetails.address_line1,
       address_line2: cardDetails.address_line2,
       address_city: cardDetails.address_city,
       address_state: addressState,
       address_zip: cardDetails.address_zip,
       address_country: country
   });

    if (error) {
      console.error('Error creating token:', error);
      setLoading(false);
      setErrorMsg(error?.message.replace(/\.$/, '') || 'Error creating token')
      return
    } else {
      // console.log('Token:', token);
      await axios.post(`${REACT_APP_BASE_URL}/v1/create-card`, {
        token:token,
        first_name: cardDetails?.first_name,
        last_name: cardDetails?.last_name,
        address_line1: cardDetails.address_line1,
        address_line2: cardDetails.address_line2,
        address_city: cardDetails.address_city,
        address_state: addressState,
        address_zip: cardDetails.address_zip,
        address_country: country 
      }, {
        headers: {
          Authorization: `Bearer ${user_token}`
        }
      }).then(async (res) => {
        setCardDetails({ first_name: "", last_name: "", address_line1: "", address_line2: "", address_city: "", address_zip: "", cvc: "", full_name: "" });
        setAddressState("CA");
        setCountry("US"); 
        if (params?.buy_plan) {
          await axios.post(`${REACT_APP_BASE_URL}/v1/subscribe`, { plan_id: REACT_APP_PREMIUM_PLAN_ID }, {
            headers: {
              Authorization: `Bearer ${user_token}`
            }
          }).then((res) => {
            setLoading(false);
            localStorage.setItem("free_trial", false);
            setLoginDetails({ ...loginDetails, free_trial: false })
            setIsUserHavingCard(true)
            setAlertMsg(res?.data?.message)
          }).catch((error) => {
            setLoading(false);
            if (error.response.data.message === "User already subscribed to this plan") {
              localStorage.setItem("free_trial", false);
              setLoginDetails({ ...loginDetails, free_trial: false })
              setAlertMsg(error.response.data.message)
            } else {
              setErrorMsg(error.response.data.message);
            }
          })
        } else {
          navigate("/")
          setIsUserHavingCard(true)
        }
      }).catch((error) => {
        // console.log(error)
        setErrorMsg(error?.response?.data?.message.replace(/\.$/, ''))
        setLoading(false)
      })
    }
 


  };



  // ******************************************************************************


  const updatedState = (country) => {
    return State.getStatesOfCountry(country).map((s) => {
      return {
        label: s.name,
        value: s.isoCode,
      };
    });
  };


  const handleDataChange = (e) => {
    setCardDetails({
      ...cardDetails,
      [e.target.name]: e.target.value
    })
  }



  const countrySelector = (e) => {
    setCountry(e.target?.value);
  };


  if (!loginDetails.isLoggedIn) {
    navigate("/");
    return
  }
  return (
   
    <div className='payment-container'>
      <Desktop>
      <Header />
      <div className="main-payment-container">
      <main className="payment-left">
        <div className="payment-banner-img">
          <img src={banner} alt="" />
        </div>
        <div className="plan-details">
          <p className="heading">Premium Membership</p>
          <div className="amount-card">
            <p className="price">US$5.00</p>
            <p style={{fontWeight:'400',fontSize:'12px'}}>per <br /> month</p>
          </div>
          <div className="billing">
            <div className="billing-details-row">
              <p className="details">Premium Membership
              <br />
              <span style={{fontSize:'16px'}}>Billed Monthly</span>
              </p>
              <p>US$5.00</p>
            </div>
            <div className="billing-details-row ">
              <p className="details">Subtotal
              </p>
              <p>US$5.00</p>
            </div>
            <div className="billing-details-row bill-total">
              <p className="details">Total due today
              </p>
              <p>US$5.00</p>
            </div>
          </div>
        </div>
      </main>
      <main className="capture-card-details">
        <form onSubmit={handlePayment} className="capture-card-form-container">
          <div>
            <section id="payment-details">
              <div className="payment-heading-section">
              <header className="capture-card-details__section__heading">
                Payment Details
              </header>
              <div className="card-icons-container">
                <div className="card-icons">
                  <img src={Visa} alt="" />
                </div>
                <div className="card-icons">
                  <img src={Mastercard} alt="" />
                </div>
                <div className="card-icons">
                  <img src={Amex} alt="" />
                </div>
              </div>
              </div>
          
              <div className="label-input-container">
                <label htmlFor="">Cardholder Name</label>
                <input
                type="text"
                name="full_name"
                autoComplete="full name"
                placeholder="Full name on card"
                required
                ref={fullNameRef}
                value={cardDetails?.full_name}
                onChange={handleDataChange}
              />
              </div>
              
                <div className="card-element-container">
                <label htmlFor="cardNumber" style={{fontSize:'14px',fontWeight:'600',marginBottom:'10px',color:'var(--offBlack)'}}>
                  Card Number
                </label>
                  <CardNumberElement id="cardNumber" className="card-element" />
              </div>
              <div className="input-row" >
              <div className="card-element-container" style={{width:'100%'}}>
                <label htmlFor="expDate" style={{fontSize:'14px',fontWeight:'600',marginBottom:'10px',color:'var(--offBlack)'}}>
                Expiration Date
                </label>
                  <CardExpiryElement id="cardExpiry" className="card-element"/>
              </div>
              <div className="card-element-container" style={{width:'100%'}}>
                <label htmlFor="CVC" style={{fontSize:'14px',fontWeight:'600',marginBottom:'10px',color:'var(--offBlack)'}}>
                  CVC
                </label>
                  <CardCvcElement id="cardCvc" className="card-element" />
              </div>
              </div>
              
            </section>
            <section id="billing-address" style={{ marginBottom: "0px" }}>
              <header className="capture-card-details__section__heading">
                Billing Address
              </header>
              <div className="input-row">
              <div className="label-input-container">
                <label htmlFor="">First Name</label>
                <input
                required
                type="text"
                name="first_name"
                autoComplete="first_name"
                value={cardDetails?.first_name}
                onChange={handleDataChange}
              /> 
              </div>
              
              <div className="label-input-container">
              <label htmlFor="">Last Name</label>
              <input
                required
                type="text"
                name="last_name"
                autoComplete="last_name"
                value={cardDetails?.last_name}
                onChange={handleDataChange}
              /> 
              </div>
              {/* */}
              </div>
              
              <div className="label-input-container">
                <label htmlFor="">Address Line 1</label>
                <input
                required
                type="text"
                name="address_line1"
                autoComplete="address line 1"
                value={cardDetails?.address_line1}
                onChange={handleDataChange}
              /> 
              </div>
              {/**/}
              <div className="input-row">
              
              <div className="label-input-container">
                <label htmlFor="">Address Line 2</label>
                <input
                type="text"
                name="address_line2"
                autoComplete="address line 2"
                placeholder="Apt, Suite, etc."
                value={cardDetails?.address_line2}
                onChange={handleDataChange}
              />
              </div>
              
              <div className="label-input-container">
                <label htmlFor="">City</label>
                <input
                type="text"
                name="address_city"
                autoComplete="city"
                required
                value={cardDetails?.address_city}
                onChange={handleDataChange}
              /> 
              </div>
              {/* */}
              </div>
              <div className="label-input-container">
              <FormControl sx={{ mb: 2, width: 1, flex: 1 }}>
              <label htmlFor="" style={{marginBottom:'10px'}}>Country</label>
                <Select
                  labelId="select-country"
                  id="demo-simple-select"
                  value={country}
                  onChange={countrySelector}
                  sx={{ height: 40, backgroundColor: "white",borderRadius:'8px',fontFamily:'Plus Jakarta Sans !important' }}
                >
                  {countryList()
                    .getData()
                    .map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              </div>
              

              <div className="input-row">
                <div className="label-input-container">
                <FormControl sx={{marginBottom:'15px'}} style={{width:'100%', marginBottom: "25px" }}>
                <label htmlFor="" style={{marginBottom:'10px'}}>State</label>
                  <Select
                    required
                    labelId="select-state"
                    id="demo-simple-select"
                    value={addressState}
                    onChange={(e) => {
                      setAddressState(e.target.value)
                      // console.log(e)
                    }}
                    sx={{ height: 40, backgroundColor: "white",borderRadius:'8px',fontFamily:'Plus Jakarta Sans !important' }}
                  >
                    {country
                      ? updatedState(country).map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))
                      : []}
                  </Select>
                </FormControl>
                </div>
                
              
                <div className="label-input-container">
                  <label htmlFor="">ZIP code</label>
                  <input
                  className="card-secure-info__input"
                  type="number"
                  name="address_zip"
                  maxLength={6}
                  inputMode="numeric"
                  required
                  value={cardDetails?.address_zip}
                  onChange={handleDataChange}
                /> 
                </div>
                {/* */}
              </div>

            </section>
          </div>
          <div className="card-details-btn">
            <button>{(loading) ? <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> : "Subscribe"}</button>
          </div>
          
        </form>
      </main>
      </div>
      
      <Footer />
      </Desktop>
      <Mobile>
        <MobileView/>
      </Mobile>
      <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => { 
        setAlertMsg(null); 
        navigate("/step1")
         }} />
      <Alert  isOpen={!!errorMsg} message={errorMsg} onClose={() => setErrorMsg(null)}/>
    </div>
  );
};


const Cb = () => {
  return (
    <Elements stripe={stripePromise}>
      <CaptureCardDetails />
    </Elements>
  );
};
export default Cb;
