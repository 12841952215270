import "./Alert.css";


const Alert = ({ isOpen, type = 'alert', message, onClose, onConfirm, style = {},email='email@email.com',needEmail=false }) => {
  if (!isOpen) return null
  return (
    <div className='alert' style={{ ...style }}>
      <div className='alert-box'>
        <div className='alert-content' style={{fontSize :needEmail ? '20px':'16px'}}>{message}</div>
        {needEmail && <p className="email" style={{padding :needEmail ? '25px 15px':'15px'}}>{email}</p>}
        <div className='alert-btn-container' style={{marginTop:needEmail? '10px':'25px'}}>
          {type !== 'alert' && <button onClick={onClose} type='button' className='alert-cancel'>Cancel</button>}
          <button onClick={type === 'alert' ? onClose : onConfirm} type='button' className='alert-ok'> Okay</button>
        </div>
      </div>
    </div>
  )
};

export default Alert;