import "./Recover.css"
import cross_icon from "../../images/cross_icon.svg"
import CircularProgress from '@mui/material/CircularProgress';


const RecoverPassword = ({ isOpen, onClose, email, setEmail, sendRecoverLink, btnLoading, onError }) => {
    if (!isOpen) {
        return
    }
    return (
        <div className="recover-pass-container">

            <div className="close-button">
                <img src={cross_icon} alt="" onClick={onClose} style={{ cursor: "pointer" }} />
            </div>
            <div className="heading-text">
                Reset password
            </div>
            <div className="sub-heading">
                Don’t worry, it happens to the best of us.
            </div>
            <div className="recovery-email-input-container">
                <label>Email</label>
                <input type="text" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                {onError ? <p className="errorMsg">{onError?.error}</p> : ''}

            </div>
            <div className="recover-btn">
                <button onClick={sendRecoverLink}>{(btnLoading) ? <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> : "Email reset code"}</button>
            </div>
        </div>

    )
}

export default RecoverPassword