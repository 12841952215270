import cross_icon from "../../images/cross_icon.svg";
import google from "../../images/google.svg"
// import facebook from "../../images/face-book.svg"
import apple from "../../images/apple.svg";
import "./SignUp.css"
import CircularProgress from '@mui/material/CircularProgress';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleSignin from 'react-apple-signin-auth';


const SignUp = ({ isOpen, onClose, onDone, email, setEmail, btnLoading, googleLogin, facebookSignUp, appleSignUp, onError,login }) => {
    if (!isOpen) return null
    return (
        <div className="signup-container ">
            <div className="close-button">
                <img src={cross_icon} alt="" onClick={onClose} />
            </div>
            <div className="text-container">
                <p className="heading">Create an account</p>
                <p className="sub-text">By creating an account, you understand and agree to Jobweaver.ai's <a href="/terms-of-service" style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline" }}> Terms</a>  and<a href="/privacy-policy" style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline" }}> Privacy policy</a> 
                {/* <a href="/cookie-policy" style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline" }}>Cookie Policy.</a>  */}
                </p>
            </div>
            <div className="button-container">
                <div className="signup-btn" onClick={googleLogin}>
                    <img src={google} alt="" />
                    <span className="google">Continue with Google</span>
                </div>


                {/* <AppleSignin
                    authOptions={{
                        clientId: "ai.Jobweaver.login",
                        redirectURI: "https://www.Jobweaver.ai",
                        scope: 'email name',

                        state: 'state',
                        nonce: 'nonce',
                        usePopup: true,
                    }}
                    uiType="dark"
                    className="apple-auth-btn"
                    noDefaultStyle={false}
                    buttonExtraChildren="Continue with Apple"
                    onSuccess={(response) => appleSignUp(response)}
                    onError={(error) => console.error(error)}
                    skipScript={false}
                    iconProp={{ style: { marginTop: '10px' } }}
                    render={(props) =>
                        <div className="signup-btn" {...props} >
                            <img src={apple} alt="" />
                            <span className="apple">Continue with Apple</span>
                        </div>
                    }
                /> */}

                {/* <FacebookLogin
                    appId="244123051700464"
                    callback={facebookSignUp}
                    fields="name,email,picture"
                    textButton="Continue with Facebook"
                    render={renderProps => (
                        <div className="signup-btn" onClick={renderProps.onClick}>
                            <img src={facebook} alt="" />
                            <span className="facebook">Continue with Facebook</span>
                        </div>
                    )}

                /> */}
            </div>
            <div className="division-container" >
                <div className="line"></div>
                <p style={{ fontWeight: "600",fontSize:'14px' }}>or</p>
                <div className="line"></div>
            </div>
            <div className="bottom-container">

                <form onSubmit={(e) => onDone(e)}>
                    <div className="input-container">
                        <label>Email</label>
                        <input type="email" value={email} placeholder="Email address" onChange={(e) => {
                            setEmail(e.target.value)
                        }} />
                        {onError ? <p className="errorMsg">{onError?.error|| 'Something went wrong'}</p> : ''}

                    </div>
                    <div className="continue-btn">
                        <button type="submit">{(btnLoading) ? <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> : "Continue with Email"}</button>
                        <p style={{ textAlign: "center",padding:'15px 0px 25px 0px',fontSize:'14px' }}>Already have an account?    <span style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline", cursor: "pointer" }} onClick={login}>Sign in</span></p>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default SignUp