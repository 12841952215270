import React, { useContext, useLayoutEffect, useState } from 'react'
import './upgradePlan.css'
import UpgradePlanAlertModel from '../../modals/UpgradePlanAlertModel/UpgradePlanAlertModel';
import { context } from '../../store/context';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Alert from '../../modals/AlertModal/Alert';
import LoadingModal from '../../modals/Loading/loading';




const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const REACT_APP_PREMIUM_PLAN_ID = process.env.REACT_APP_PREMIUM_PLAN_ID


const UpgradePlan = () => {
    const navigate = useNavigate();
    const { loginDetails, setLoginDetails, isUserHavingCard, setLoginModalOpen, setUserHavingPlan, userHavingPlan } = useContext(context);
    const [isFreeTrial, setIsFreeTrial] = useState();
    const [upgradeMsg, setUpgradeMsg] = useState(null)
    const [alertMsg, setAlertMsg] = useState(null);

    const [loading2, setLoading2] = useState(false)




    useLayoutEffect(() => {
        const freeTrialStatus = localStorage.getItem('free_trial')
        if ((typeof freeTrialStatus) === 'string') {
            if (freeTrialStatus === 'false') {
                setIsFreeTrial(false)
            } else {
                setIsFreeTrial(true)
            }
        }
        else {
            setIsFreeTrial(freeTrialStatus)
        }
    }, [userHavingPlan])




    const handleSubscription = async (plan_id) => {
        if (!loginDetails.isLoggedIn) {
            return //setLoginModalOpen(true)
        } else if (!isUserHavingCard) {
            navigate("/payment-details", {
                state: {
                    buy_plan: true
                }
            })
        } else {

            setLoading2(true)
            const token = localStorage.getItem("token");
            await axios.post(`${REACT_APP_BASE_URL}/v1/subscribe`, { plan_id: plan_id }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async(res) => {
                setUserHavingPlan(true)
                localStorage.setItem("free_trial", false);
                setIsFreeTrial(false)
                await setLoginDetails({ ...loginDetails, free_trial: false })
                setLoading2(false)
                 setAlertMsg(res?.data?.message || "Your plan is upgraded");
                //     navigate("/step1")
            }).catch((error) => {
                console.log(error);
                if (error.response.data.message === "User already subscribed to this plan") {
                    localStorage.setItem("free_trial", false);
                    setLoginDetails({ ...loginDetails, free_trial: false })
                    setLoading2(false)
                    navigate("/step1")
                } else {
                    setAlertMsg(error.response.data.message);
                }
                setLoading2(false)
            })
        }
    }

    const handleModel = () => {
        setUpgradeMsg("Want to upgrade your plan ?")
    }

    const handleOnSubmit = () => {
        if (REACT_APP_PREMIUM_PLAN_ID) {
            handleSubscription(REACT_APP_PREMIUM_PLAN_ID)
        }
        setUpgradeMsg(null)
    }

    return (

        <>
            {isFreeTrial ?

                <p className="regulation" style={{color:'var(--offBlack)'}}>
                    Limit one search per day.
                    <span
                        onClick={() => handleModel()}
                        style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline", cursor: "pointer" }}
                    >
                        Go premium
                    </span>
                </p>

                : <p className="regulation" style={{opacity:'0'}}>Limit one search per day</p>
            }
            <UpgradePlanAlertModel isOpen={!!upgradeMsg} message={upgradeMsg} type='submit' onConfirm={handleOnSubmit} onClose={() => setUpgradeMsg(null)} loading={loading2}/>
            <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
            <LoadingModal isOpen={loading2} />

        </>
    )
}

export default UpgradePlan