import cross_icon from "../../images/cross_icon.svg";
import google from "../../images/google.svg"
// import facebook from "../../images/face-book.svg"
import apple from "../../images/apple.svg";
import "./Login.css"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleSignin from 'react-apple-signin-auth';

const Login = ({ isOpen, onClose, onDone, email, setEmail, password, setPassword, btnLoading, onRegisterClick, onCLickEye, showPass, googleLogin, facebookLogin, appleLogin, onForgetPass, onError }) => {
    if (!isOpen) return null
    return (
        <div className="login-container ">
            <div className="close-button">
                <img src={cross_icon} alt="" onClick={onClose} />
            </div>
            <div className="text-container">
                <p className="heading">Welcome back</p>
            </div> 

            <div className="bottom-container">
                <div className="input-container">
                    <label>Email</label>
                    <input type="email" value={email} placeholder="Email address" onChange={(e) => setEmail(e.target.value)} />

                </div>

                <div className="input-container pass-input-container">
                    <label>Password</label>
                    <div className="input-container-box">

                        <input className="" type={(showPass) ? "text" : "password"} placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <span className="eye-icon" onClick={onCLickEye}>{(showPass) ? <VisibilityIcon style={{color:'var(--offBlack)',opacity:'0.7'}}/> : <VisibilityOffIcon style={{color:'var(--offBlack)',opacity:'0.7'}}/>} </span>
                    </div>
                    <p style={{ textAlign: "right", color: "rgba(48, 98, 76, 1)", textDecoration: "underline", cursor: "pointer",fontSize:'14px',paddingBottom:'10px' }} onClick={onForgetPass}>Forgot Password</p>

                </div>
                {onError ? <p className="errorMsg">{onError?.error}</p> : ''}

                <div className="login-button">
                    <button onClick={onDone}>{(btnLoading) ? <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> : "Sign in"}</button>
                </div>
            </div>
            <div className="division-container">
                <div className="line"></div>
                <p style={{ fontWeight: "600",fontSize:'14px' }}>or</p>
                <div className="line"></div>
            </div>
            <div className="button-container">
                <div className="login-btn" onClick={googleLogin}>
                    <img src={google} alt="" />
                    <span className="google">Sign in with Google</span>
                </div>



                {/* <AppleSignin
                    authOptions={{
                        clientId: "ai.Jobweaver.login",
                        redirectURI: "https://www.Jobweaver.ai",
                        scope: 'email name',

                        state: 'state',
                        nonce: 'nonce',
                        usePopup: true,
                    }}
                    uiType="dark"
                    className="apple-auth-btn"
                    noDefaultStyle={false}
                    buttonExtraChildren="Sign in with Apple"
                    onSuccess={(response) => appleLogin(response)}
                    onError={(error) => console.error(error)}
                    skipScript={false}
                    iconProp={{ style: { marginTop: '10px' } }}
                    render={(props) =>
                        <div className="login-btn" {...props} >
                            <img src={apple} alt="" />
                            <span className="apple">Sign in with Apple</span>
                        </div>
                    }
                /> */}
                {/* <FacebookLogin

                    appId= '244123051700464' 

                    callback={facebookLogin}
                    fields="name,email,picture"
                    textButton="Continue with Facebook"
                    render={renderProps => (
                        <div className="login-btn" onClick={renderProps.onClick}>
                            <img src={facebook} alt="" />
                            <span className="facebook">Sign in with Facebook</span>
                        </div>
                    )}

                /> */}
            </div>
            <p style={{ textAlign: "center",padding:'14px 0px 8px 0px',fontSize:'14px' }}>New to Jobweaver.ai? <span style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline", cursor: "pointer" }} onClick={onRegisterClick}>Create an account</span></p>
        </div>
    )
}

export default Login