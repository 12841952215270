import "./success.css"
import img from "../../images/success.svg"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import { useNavigate } from "react-router-dom"
import { context } from "../../store/context"
import { useContext } from "react"
import UpgradePlan from "../../components/UpgradePlan/UpgradePlan"
import { Desktop, Mobile } from "../responsive/responsive"
import MobileView from "../../components/MobileView/mobile"

const Success = () => {
  const navigate = useNavigate();

  const { setLoginModalOpen, loginDetails } = useContext(context);


  if (!loginDetails.isLoggedIn) {
    navigate("/");
    // setLoginModalOpen(true)
    return
  }
  return (
    <div>
      <Desktop>
      <Header />
      <div className="success-container">
        <p className="heading">Success!</p>
        <p className="sub-heading">Your report is on its way</p>
        <div className="banner-img">
          <img src={img} alt="" />
        </div>
        <p className="sub-text">Start applying to your fresh job leads right now to maximize your chances!</p>
        <div className="home-btn">
          <button onClick={() => navigate("/")}>Home</button>
        </div>
        <UpgradePlan/>
      </div>
      <Footer hasFixed={true}/>
      </Desktop>
      <Mobile>
        <MobileView/>
      </Mobile>
    </div>
  )
}

export default Success