import { useContext, useEffect, useState } from 'react';
import './DownGradePlanAlert.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { context } from '../../store/context';
import { CircularProgress } from '@mui/material';
import checkMark from '../../images/checkmark.png'

const DowngGradePlanAlertModel = ({ isOpen, type = 'alert', message, onClose, onConfirm, style = {} , loading}) => {
  const { loginDetails, setLoginDetails, isUserHavingCard, setLoginModalOpen, userPlanDetails, setUserPlanDetails, userHavingPlan, setUserHavingPlan } = useContext(context);

  const [remainDays, setRemainDays] = useState(0)


  useEffect(() => {
    const currentDate = new Date();
    const targetDate = new Date(userPlanDetails?.expiry_date);
    const timeDifference = Number(targetDate.getTime() - currentDate.getTime());
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    setRemainDays(daysDifference)
  }, [userPlanDetails])


  if (!isOpen) return null
  return (
    <div className='dg_plan_alert' style={{ ...style }}>
    <div className='dg_plan_alert-box' >
    <div className='plan-details-box'>
        <p className='heading'>Downgrade to Basic</p>
        <div className='plan-detail'>
          <img src={checkMark} alt="" width='20px' height='20px'/>
          <span className='text'> Your plan will expire with in {remainDays} Days. </span>

        </div>
    </div>
    <p  style={{textAlign:'center',padding:"15px",color:"red"}}> Do you want to unsubscribe your plan ? </p>
    <div className='btn-container'>
      <button className='downgrade' disabled={loading} onClick={onConfirm}>
    {(loading) ? <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> : 'Downgrade to Basic'} 
      </button>
      <button className='cancel' onClick={onClose}>
      Maybe later
      </button>
    </div>
    </div>
    
  </div>
  )
}

export default DowngGradePlanAlertModel