import "./changePassword.css"
import cross_icon from "../../images/cross_icon.svg"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ChangePassword = ({isOpen,onClose,btnLoading,newPass,setNewPass,confirmPass,setConfirmPass,changePassword,onError,showPass=false,onCLickEye}) =>{
    if(!isOpen){
        return
    }
    return (
        <div className="change-pass-container">
            <div className="close-button">
                <img src={cross_icon} alt="" style={{cursor:"pointer"}} onClick={onClose}/>
            </div>
            <div className="heading-text">
                Reset Password
            </div>
            <div className="change-pass-input-container">
                <label>New Password</label>
                <input type= {(showPass)? "text" : "password"} value={newPass} onChange={(e)=>setNewPass(e.target.value)}/>
                <span className="eye-icon" onClick={onCLickEye}>{(showPass) ? <VisibilityIcon style={{color:'var(--offBlack)',opacity:'0.7'}}/> : <VisibilityOffIcon style={{color:'var(--offBlack)',opacity:'0.7'}}/>} </span>
            </div>
            <div className="change-pass-input-container">
                <label>Confirm Password</label>
                <input type="password" value={confirmPass} onChange={(e)=>setConfirmPass(e.target.value)}/>
            </div>
      {onError ? <p className="errorMsg  " style={{width:'80%',margin:'auto'}}>{onError?.error}</p> : ''}

            <div className="change-pass-btn">
                <button onClick={changePassword}>{(btnLoading) ? <CircularProgress sx={{color: "darkgray",scale:"0.6"}} /> : "Change Password"}</button>
            </div>
        </div>
    )
}

export default ChangePassword