import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MobileView from "../../components/MobileView/mobile";
import { Desktop, Mobile } from "../responsive/responsive";
import "./Cookie.css";

const Cookie = () => {
  return (
    <>
    <Desktop>
    <Header />
      <div className="cookie-container">
        <h1 className="title">Cookie Policy</h1>
        <p className="para">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem quos rerum odio exercitationem dicta tempora sapiente autem iusto eius deserunt, facere dolores illum! Aperiam facere voluptatibus ab fuga nobis possimus!.
        </p>

        <h2 className="subtitle">adipisicing elit?</h2>
        <p className="para">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Pariatur voluptatum alias necessitatibus illum suscipit deserunt quisquam error omnis quis ratione, temporibus perferendis dolor dolore nam aspernatur incidunt eum vel fugit doloremque laboriosam quos animi beatae ab optio! Ratione vitae doloremque atque quam esse illo optio odit quos? Et a eos illum numquam cupiditate error saepe laboriosam, alias iure molestiae adipisci.
        </p>

        <h2 className="subtitle">sit amet consectetur adipisicing</h2>
        <p className="para">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis, odio.:
        </p>
        <ul className="list">
          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, hic!</li>
          <li>Lorem ipsum dolor sit amet consectetur.</li>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
          <li>Lorem ipsum dolor sit.</li>
          <li>Lorem ipsum dolor sit amet consectetur.</li>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
        </ul>

        <h2 className="subtitle">consectetur adipisicing</h2>
        <p className="para">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi incidunt quia ducimus, aliquam at vero alias iste quam rerum temporibus illum aliquid, debitis sint? Ullam, omnis dolor. Sunt ut inventore non repellat facere quia ad! Et magnam perspiciatis placeat officiis, asperiores aperiam aspernatur libero aut. Doloribus, exercitationem porro. Amet quae odio, sunt sint aut ipsa maxime illum dolorem laudantium hic.
        </p>

        <h2 className="subtitle">Lorem ipsum dolor sit.</h2>
        <p className="para">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptate consequatur amet quam repellat tempore nostrum harum autem, provident fugiat doloremque error illum excepturi tempora non accusantium veritatis id? Tempore minima impedit unde quas magnam dolor iusto id quae doloribus quis, voluptas possimus tempora praesentium voluptatibus! Eveniet assumenda similique, placeat culpa error, ab voluptatibus maxime minus saepe nesciunt sint laborum doloremque dolorum quas laboriosam velit dicta iusto voluptates itaque reiciendis veniam omnis? Dicta mollitia alias praesentium ducimus deserunt. Neque, iure itaque.
        </p>

        <h2 className="subtitle">Lorem ipsum dolor sit amet.</h2>
        <p className="para">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda, minus soluta ducimus possimus sit eum explicabo iste pariatur magni ad!
        </p>

        <h2 className="subtitle">adipisicing elit</h2>
        <p className="para">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque et laudantium laborum distinctio asperiores dolore temporibus ipsum officiis. Eos, impedit sed aspernatur atque architecto molestiae.
        </p>
      </div>
      <Footer />
    </Desktop>
    <Mobile>
      <MobileView/>
    </Mobile>
      
    </>
  );
};

export default Cookie;
