import "./Form1.css"
import img from "../../images/urlFormImage.svg"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import { useNavigate } from "react-router-dom"
import { context } from "../../store/context"
import { useContext, useEffect, useState } from "react"
import UpgradePlan from "../../components/UpgradePlan/UpgradePlan"
import { Desktop, Mobile } from "../responsive/responsive"
import MobileView from "../../components/MobileView/mobile"


const Form1 = () => {
    const navigate = useNavigate();
    const { formDetails, setFormDetails, loginDetails, setLoginModalOpen } = useContext(context);

    useEffect(()=>{
        if (!loginDetails.isLoggedIn) {
            navigate("/");
            return
        }
    },[])
    
    return (
        <div>
            <Desktop>
            <Header />
            <div className="main-container">
                <p className="heading">Begin a search</p>
                <div className="banner-img">
                    <img src={img} alt="" />
                </div>
                <p className="sub-heading">Enter your desired job title to get started</p>
                <form action="" className="form-container" onSubmit={() => navigate("/step2")}>
                    <div className="input-container">
                        <>
                            <label >Enter Job Title</label>
                            <input type="text" required placeholder="Title" value={formDetails?.job_title} onChange={(e) => { setFormDetails({ ...formDetails, job_title: e.target.value }) }} />
                        </>
                    </div>
                    <div className="button-container">
                        <button >Next</button>
                    </div>
                </form>
                <div className="dot-container">
                    <span className="dot1"></span>
                    <span className="dot2"></span>
                    <span className="dot3"></span>

                </div>

                <UpgradePlan />
            </div>
            <Footer style={{height:'130px'}} hasFixed={true}/>
            </Desktop>
            <Mobile>
                <MobileView/>
            </Mobile>
            
        </div>
    )
}

export default Form1