import { useMediaQuery } from "react-responsive";

export const Mobile = ({children, className=null}) => {
    const isMobile = useMediaQuery({
        maxWidth: 1023
    })

    return isMobile ? className ? <div className={className}>{children}</div> : children : null
}

export const Desktop = ({children}) => {
    const isDesktop = useMediaQuery({
        minWidth: 1024
    })

    return isDesktop ? children : null
}
