import React, { useEffect, useRef, useState } from 'react';
import './CustomDropdown.css';
import { RxCross1 } from 'react-icons/rx'
import { GoChevronDown } from 'react-icons/go'

const CustomDropdown = ({ placeholder, options, setDetails, value, type }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);

    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const handleOptionSelect = (option) => {

        let newSelectedOptions;
        if (type === 'radio') {
            newSelectedOptions = [option];
        } else {
            if (selectedOption.includes(option)) {
                newSelectedOptions = selectedOption.filter((item) => item !== option);
            } else {
                newSelectedOptions = [...selectedOption, option];
            }
        }
        setSelectedOption(newSelectedOptions);
        setDetails(newSelectedOptions);
    };

    const handleReset = () => {
        setSelectedOption([]);
        setDetails([]);

    }

    useEffect(() => {
        setSelectedOption(value || []);

    }, [value])



    // --------hide dropdown when click outside of dropdown----
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    // ----------count the selected items ----------
    const headerShow = () => {
        if (selectedOption && selectedOption.length === 0) {
            return placeholder || 'Select an option'
        }
        if (selectedOption && selectedOption.length === 1) {
            return selectedOption[0]
        }
        else {
            const count = selectedOption.length;
            return <>
                {placeholder} <span className='count-selected'>{count}</span>
            </>
        }
    }

    return (
        <div ref={dropdownRef} className={`custom-dropdown ${isOpen ? 'open' : ''}`}>
            <div className={`dropdown-header ${selectedOption?.length ? 'dropdown-selected' : ''}`} onClick={toggleDropdown}>
                <span className={selectedOption?.length ? 'selected-option' : ''}>
                    {headerShow()}
                </span>
                <span className='down-arrow-icon'>
                    <GoChevronDown />
                </span>

            </div>
            <div className="dropdown-options">
                <div className='cancel-icon' onClick={toggleDropdown}>
                    <RxCross1 />
                </div>
                <div className='option-wrapper'>

                    {options && options.length > 0 && options.map((option) => (
                        <label
                            htmlFor={option.label+placeholder}
                            key={option.value}
                            className="dropdown-option"
                        >
                            <input
                                type={type === "radio" ? 'radio' : "checkbox"}
                                id={option.label+placeholder} name="options"
                                onChange={() => handleOptionSelect(option.value)}
                                checked={
                                    selectedOption.includes(option.value)
                                }
                            />
                            <span>{option.label}</span>
                        </label>
                    ))}
                </div>
                <div className='dw-func-box'>
                    <button className='btn-white' onClick={handleReset}>Reset</button>
                </div>
            </div>
        </div>
    );
};

export default CustomDropdown;
