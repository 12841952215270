import DropDown from "../../components/Dropdown/DropDown";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import img from "../../images/FilterFormImg.svg";
import "./Filter.css";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from "react";
import AdditionalForm from "../../modals/AdditionalForm/AdditionalForm";
import { context } from "../../store/context"
import { useContext } from "react"
import Alert from "../../modals/AlertModal/Alert";
import UpgradePlan from "../../components/UpgradePlan/UpgradePlan";
import CustomDropdown from "../../components/Dropdown/CustomDropdown/CustomDropdown";
import { Desktop, Mobile } from "../responsive/responsive";
import MobileView from "../../components/MobileView/mobile";
import { GoChevronRight } from "react-icons/go";
import {  SwipeableDrawer } from '@mui/material';

const datePosted = [
  { value: "Any Time", label: "Any Time" },
  { value: "Past Month", label: "Past Month" },
  { value: "Past Week", label: "Past Week" },
  { value: "Past 24 hours", label: "Past 24 hours" }
]

const experience_Level = [
  { value: "Internship", label: "Internship" },
  { value: "Entry Level", label: "Entry Level" },
  { value: "Associate", label: "Associate" },
  { value: "Mid Senior Level", label: "Mid Senior Level" },
  { value: "Director", label: "Director" },
  { value: "Executive", label: "Executive" }
]

const salary_options = [
  { value: 40000, label: `${(new Intl.NumberFormat("en-US",{
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
}).format(40000))}+`} ,
  { value: 60000, label: `${(new Intl.NumberFormat("en-US",{
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
}).format(60000))}+`  },
  { value: 80000, label: `${(new Intl.NumberFormat("en-US",{
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
}).format(80000))}+`   },
  { value: 100000, label:  `${(new Intl.NumberFormat("en-US",{
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
}).format(100000))}+`},
  { value: 120000, label:`${(new Intl.NumberFormat("en-US",{
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
}).format(120000))}+`},
  { value: 140000, label:`${(new Intl.NumberFormat("en-US",{
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
}).format(140000))}+` },
  { value: 160000, label: `${(new Intl.NumberFormat("en-US",{
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
}).format(160000))}+`   },
  { value: 180000, label:`${(new Intl.NumberFormat("en-US",{
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
}).format(180000))}+`  },
  { value: 200000, label:`${(new Intl.NumberFormat("en-US",{
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
}).format(200000))}+`   }
]

const job_type_options = [
  { value: "Full-Time", label: "Full-Time" },
  { value: "Part-Time", label: "Part-Time" },
  { value: "Contract", label: "Contract" },
  { value: "Temporary", label: "Temporary" },
  { value: "Volunteer", label: "Volunteer" },
  { value: "Internship", label: "Internship" }
]

const work_type_options = [
  { value: "Onsite", label: "Onsite" },
  { value: "Remote", label: "Remote" },
  { value: "Hybrid", label: "Hybrid" },
]

const FilterForm = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const { setLoginModalOpen, loginDetails, formDetails, setFormDetails, date_posted, setDate_posted, exp_level, setExp_level, salary, setSalary, job_type, setJob_type, work_type, setWork_type } = useContext(context);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [open]);

  const handleNext = () => {
    // if (!date_posted) {
    //   return setAlertMsg("Please select posted date")
    // } else if (!exp_level) {
    //   return setAlertMsg("Please select Experience level")
    // } else if (!salary) {
    //   return setAlertMsg("Please select salary")
    // } else if (!job_type) {
    //   return setAlertMsg("Please select job type")
    // } else if (!work_type) {
    //   return setAlertMsg("Plese select work type")
    // } else {
    //   // remove spaces in multiple input;
    //   setFormDetails({
    //     ...formDetails,
    //     date_posted: date_posted?.join(', '),
    //     exp_level: exp_level.join(', '),
    //     salary: salary.join(', '),
    //     job_type: job_type.join(', '),
    //     work_type: work_type.join(', ')
    //   })
    //   navigate("/review")
    // }
    setFormDetails({
      ...formDetails,
      date_posted: date_posted && date_posted?.length > 0 ? date_posted?.join(', ') : "Any Time",
      exp_level: exp_level && exp_level?.length > 0 ? exp_level?.join(', ') : "NA",
      salary: salary && salary?.length > 0 ? salary?.join(', ') : "NA",
      job_type: job_type && job_type?.length > 0 ? job_type?.join(', ') : "NA",
      work_type: work_type && work_type?.length > 0 ? work_type?.join(', ') : "NA"
    })
    navigate("/review")
  }
  if (!loginDetails.isLoggedIn) {
    navigate("/");
    // setLoginModalOpen(true)
    return
  }
  return (
    <div>
      <Desktop>
      {(open) ? <div className="upper-container" onClick={()=>setOpen(false)}></div> : null}
      <Header />
      <div className="filter-container">
        <p className="heading">Add filters</p>
        <div className="banner-img">
          <img src={img} alt="" />
        </div>
        <p className="sub-heading">
        Time to apply filters to find your perfect matches </p>
        <div className="drop-down-container">
          <CustomDropdown placeholder="Date Posted" options={datePosted} type='radio' value={date_posted} setDetails={setDate_posted} />
          <CustomDropdown placeholder="Experience Level" options={experience_Level} value={exp_level} setDetails={setExp_level} />
          <CustomDropdown placeholder="Salary" options={salary_options} value={salary} setDetails={setSalary} />
          <CustomDropdown placeholder="Job Type" options={job_type_options} value={job_type} setDetails={setJob_type} />
          <CustomDropdown placeholder="Work Type" options={work_type_options} value={work_type} setDetails={setWork_type} />



          {/* <DropDown placeholder="Date Posted" options={datePosted} name="date_posted" value={date_posted} setDetails={setDate_posted} />
          <DropDown placeholder="Experience Level" options={experience_Level} value={exp_level} setDetails={setExp_level} />
          <DropDown placeholder="Salary" options={salary_options} value={salary} setDetails={setSalary} />
          <DropDown placeholder="Job Type" options={job_type_options} value={job_type} setDetails={setJob_type} />
          <DropDown placeholder="Work Type" options={work_type_options} value={work_type} setDetails={setWork_type} /> */}
          <div className="more-btn dropdown-header" onClick={()=>setOpen(true)}><span>More</span>  <GoChevronRight style={{paddingTop:''}}/></div>
        </div>
        <div className="button-container">
          <button className="back-btn" onClick={() => navigate("/step1")}>Back</button>
          <button className="next-btn" onClick={handleNext}>Next</button>
        </div>
        <div className="dot-container">
          <span className="dot1"></span>
          <span className="dot2" style={{ backgroundColor: "#58B56F", border: "none" }}></span>
          <span className="dot3"></span>
        </div>

        <UpgradePlan />
      </div>
  
      <Footer style={{height:'130px'}} hasFixed={true}/>

        <AdditionalForm isOpen={open} onClose={() => setOpen(false)}  />


      <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
      </Desktop>
      <Mobile>
        <MobileView/>
      </Mobile>
    </div>
  );
};

export default FilterForm;
