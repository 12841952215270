import './mobile.css';
import mobile_default from '../../images/mobile_default.png'

const MobileView = () =>{
return(
    
    <div className='outer-container'>
        <div className="mobile-container">
            <img src={mobile_default} alt="default" />
            <p className='sub-title'>Jobweaver.ai  is not yet available on mobile</p>
            <p className='title'>Try again on desktop</p>
        </div>
    </div>
)
}

export default MobileView