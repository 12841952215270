import { RxCross1 } from "react-icons/rx";
import React, { useEffect, useState } from "react";
import "./TagInput.css";

const TagInput = ({ tags, handleChange,clearTags=false,placeholder }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [inp, setInp] = useState("");
  const [fixedTags,setFixedTags] =useState([]);

  useEffect(() => {
    setSelectedTags(tags)
  }, [])

  useEffect(()=>{
    clearTags && setSelectedTags([])
  },[clearTags])

  const removeTags = (indexToRemove) => {
    const newTags = [
      ...selectedTags.filter((_, index) => index !== indexToRemove),
    ];
    setSelectedTags(newTags);
    handleChange(newTags);
  };
  const addTags = () => {
    if(inp.trim() !== ""){
      let inputs = inp.trim().split(",").filter((value,i)=> value !== "")
      setSelectedTags([...selectedTags,...inputs])
      handleChange([...selectedTags,...inputs])
      setInp("");
    }
      
}       
   
    
  const handleInputChange = (e) => {
    setInp(e.target.value);
  };

  return (
    <div className="tag-input-container">
      <div className="taginput-input" style={{padding:selectedTags.length >=2 ? '10px':''}}>
        {selectedTags.map((tag, index) => (
          <li key={index} className="taginput-tag">
            <span className="taginput-tag-title">{tag}</span>
            
              <RxCross1 onClick={() => removeTags(index)} style={{fontSize:"12px",cursor:'pointer'}}/>
            
          </li>
        ))}
        <input
          className="taginput-input-elem"
          type="text"
          value={inp}
          onChange={handleInputChange}
          placeholder={placeholder}
          // disabled={selectedTags.length > 0}
        />
      </div>
      {/* <p className="instruction">For multiple inputs, separate with commas</p> */}
      <div className="button-container">
            <button className="reset-btn" disabled={selectedTags.length == 0} onClick={()=>{setSelectedTags([]);handleChange([])}}>Reset</button>
            <button className="apply-btn"  onClick={addTags}>{ selectedTags.length > 0 ? "Applied" :"Apply"}</button>
      </div> 
    </div>
  );
};
export default TagInput;
