import "./App.css";
import Home from "./main/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Form1 from "./main/UrlForm/Form1";
import FilterForm from "./main/FilterForm/Filter";
import Review from "./main/Review/Review";
import Success from "./main/success/success";
// import { context } from './store/context';
// import { useContext } from 'react';
import CaptureCardDetails from "./main/PaymentDetails/payment";
import Terms from "./main/TermsPage/Terms";
import Privacy from "./main/PrivacyPage/Privacy";
import Cookie from "./main/CookiePage/Cookie";

function App() {
  // const {loginDetails} = useContext(context);
  return (
    <div className="app">
      <BrowserRouter>
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path="/step1" element={<Form1 />} />
          <Route path="/step2" element={<FilterForm />} />
          <Route path="/review" element={<Review />} />
          <Route path="/success" element={<Success />} />
          <Route path="/payment-details" element={<CaptureCardDetails />} />
          <Route path="/terms-of-service" element={<Terms/>}/>
          <Route path="/privacy-policy" element={<Privacy/>}/>
          <Route path="/cookie-policy" element={<Cookie/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
