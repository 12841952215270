import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MobileView from "../../components/MobileView/mobile";
import { Desktop, Mobile } from "../responsive/responsive";
import "./Terms.css";
const Terms = () => {
  return (
    <>
      <Desktop>
        <Header />
        <div className="terms-container">
          <h1 className="title">Terms of Service</h1>
          <p className="para">
            When you individually or as an entity interact with the website of
            Jobweaver.ai (the “Website/Site”) these Terms of Use and the Privacy
            Policy (Agreement) of the site, apply to you, which is a legally
            binding agreement between you and Jobweaver.ai.
          </p>
          <p className="para">
            Please read these terms carefully before using the site. By using
            the site, you agree to be bound by these Terms of Use and create a
            legal contract between you and Jobweaver.ai. If you do not agree to
            these Terms of Use, please do not use this Site which is intended
            for use by individuals looking for employment or helping their
            connections find employment and for employers or recruiters seeking
            candidates for employment. These terms apply only to site access and
            to features or services offered on the site
          </p>
          <p className="subtitle">1. Services and Account Creation:</p>
          <p className="para">
            You must be at least 16 years of age (unless you are not prohibited
            by the applicable law), to access or use the services for your
            personal and non-commercial use. For any commercial use, you need to
            enter into a separate agreement with us and if your account(s) are
            terminated or banned then you shall not use the services.
          </p>
          <p className="para">
            It is mandatory to register for an account by providing accurate and
            complete information about yourself to use the services of
            Jobweaver.ai. False identity or information shall not be used to create
            an account. You can only have one account at a time. Jobweaver.ai has
            the sole discretion to accept, reject or terminate the account for
            any reason and you shall not use the site if you have been
            previously removed.
          </p>
          <p className="subtitle">2. Your Responsibilities:</p>
          <p className="para">
            It is your responsibility to maintain the confidentiality of the
            password and the account and be responsible for all the activities
            pertaining to the account including by any third parties. You shall
            notify Jobweaver.ai of any unauthorized use or if your account is
            breached.{" "}
          </p>
          <p className="subtitle2">You further agree not to</p>
          <ul className="list">
            <li>
              Copy or distribute any part of the Services, including by way of
              automated or non-automated “scraping”;
            </li>
            <li>
              Use any automated systems including “robots” and “spiders”;{" "}
            </li>{" "}
            <li>
              Interfere with or compromise Our systems’ integrity or decipher
              any server transmissions;
            </li>
            <li>Impose any unreasonably large load on Our infrastructure;</li>
            <li> Upload viruses or worms to the Service;</li>
            <li>
              Collect or retain any personally identifiable information
              contained in the Service beyond the permitted use hereunder;
            </li>
            <li>
              {" "}
              Access the Service by any means other than authorized herein,
              including virtual private networks which are expressly forbidden;
            </li>{" "}
            <li>Stalk, harass, bully or harm others;</li>
            <li>Impersonate any person or entity; or</li>{" "}
            <li>
              {" "}
              Hack, spam, phish, or otherwise provide fraudulent, manipulative,
              or inflammatory content.
            </li>
          </ul>
          <p className="subtitle">3. Third Party Agents and Services:</p>
          <p className="para">
            You warrant that any information of third parties provided to
            Jobweaver.ai for any reason whatsoever including job matching services
            is provided with legal consent of such parties. Key terms, that
            include employment start date, compensation etc., will be provided
            to Jobweaver.ai. You as a client may allow third party agents to use
            the services of Jobweaver.ai, but will be totally responsible for their
            actions and any breach of this Agreement by a third party agent will
            be deemed as a breach by you.
          </p>
          <p className="para">
            Jobweaver.ai may provide to you, or provide your material/information
            to, certain third-party services or providers (“Third-Party
            Service(s)”) who in turn may provide to you links to job postings,
            sites, email and contact information and other offers outside of
            that in Jobweaver.ai. These Third-Party Services are provided “AS IS”
            without any form of indemnification, support, or warranty, and this
            Agreement does not apply to your usage of such Third-Party Services.
            It is your responsibility to evaluate these services and may choose
            not to use of the Third-Party Services, because you may give these
            service providers permission to your use your information beyond
            what you agreed in this Agreement.
          </p>
          <p className="para">
            Jobweaver.ai is not responsible for, nor endorses any features,
            content, advertising, products or other materials on or available
            from such Third-Party Services.
          </p>
          <p className="subtitle">4. User Generated Content:</p>
          <p className="para">
            User generated content, is any content you post, transmit, or submit
            through Jobweaver.ai and is intended to be shared with other users. You
            warrant that you own the content or that that you have the right and
            permission to license any personally identifiable information to us.{" "}
          </p>
          <p className="para">
            You further represent and warrant that the content does not infringe
            on the intellectual property rights, privacy rights, publicity
            rights, or other legal rights of any third-party. Jobweaver.ai is not
            under any obligation to review any content posted by our users on
            our site, though we can at any time. Jobweaver.ai reserves all defenses
            made available to us by the Communications Decency Act and any other
            applicable laws, rules, or regulations. Jobweaver.ai may refuse to
            accept or display the content, and may remove or delete all or any
            portion of the content at any time. Jobweaver.ai is not responsible for
            the accuracy, usefulness, safety, or intellectual property rights of
            or relating to such content. You further understand and acknowledge
            that you may be exposed to content that is inaccurate, offensive,
            indecent, or objectionable, and you agree to waive, and hereby do
            waive, any legal or equitable rights or remedies you have or may
            have against Jobweaver.ai. Any content you upload will be treated as
            public information and is not confidential and waive all rights with
            respect to any such claims of confidentiality. Jobweaver.ai has no
            control or liability over what other users do with your content.
          </p>
          <p className="para">
            Users retain all rights to the content they upload or create on or
            through Jobweaver.ai. If you submit comments or ideas about current or
            future offerings, including without limitation potential
            improvements to our products or services you understand that any
            such submissions are unsolicited and we may use them without
            restriction and you will not be entitled to any compensation.
          </p>
          <p className="subtitle">5. Grant of Rights:</p>
          <p className="para">
            You hereby grant to Jobweaver.ai and its owners, affiliates,
            representatives, licensees, licensors and assigns a non-exclusive,
            fully paid, royalty-free, world-wide, perpetual, universal,
            transferable license to display, modify, publicly perform,
            distribute, store, broadcast, transmit and reproduce your content,
            logo(s), service marks, trademarks and trade names in conjunction
            with the services, including in developing, enhancing, and
            supporting the services. You waive all moral rights in and to all of
            the materials licensed. Jobweaver.ai reserves the right to display
            advertisements in connection with the content.
          </p>
          <p className="subtitle">6. Limitation of Liability:</p>
          <p className="para">Jobweaver.ai is not liable for</p>
          <ol className="list">
            <li> Any content posted on the site </li>
            <li>
              {" "}
              Contracts or other obligations that may arise between users
            </li>
            <li>
              Any damages that result through your use of our services in
              violation of this Agreement
            </li>{" "}
            <li>
              Any negative or critical comments that may be posted by other
              users through the site;
            </li>{" "}
            <li>
              Any of the Third-Party Service(s) you may be provided pursuant to
              your use of the site; or{" "}
            </li>
            <li>
              Any third-party personally identifiable information you upload or
              provide to Jobweave.
            </li>
          </ol>
          <p className="para">
            Jobweaver.ai SHALL NOT BE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            EXEMPLARY, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION
            WITH THE SITE, WHETHER OR NOT Jobweaver.ai HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES, OR FOR ANY DAMAGES FOR PERSONAL OR
            BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION
            WITH THIS AGREEMENT, OR FROM ANY COMMUNICATIONS, INTERACTIONS OR
            MEETINGS WITH OTHER USERS OF THE SITE, ON ANY THEORY OF LIABLITY
            (INCLUDING CONTRACT, TORT, NEGLIGENCE OR WHATSOEVER) ARISING OUT OF,
            IN CONNECTION WITH, OR RESULTING FROM: <br />
            <br /> (I) THE USE OR INABILTY TO USE THE SERVICES;
            <br />
            <br /> (II) STATEMENTS OR CONDUCT OF ANY THIRD-PARTY ON THE
            SERVICES; OR
            <br />
            <br /> (III) ANY OTHER MATTER RELATED TO THE SERVICES.{" "}
          </p>
          <p className="para">
            TO THE MAXIMUM EXTEND PERMITTED BY LAW, UNDER NO CIRCUMSTANCES WILL
            Jobweaver.ai BE LIABLE TO A USER, REGARDLESS OF WHETHER THE CLAIM IS
            BASED IN CONTRACT, TORT INCLUDING NEGLIGENCE, STRICT LIABLITY, OR
            OTHERWISE, FOR MORE THAN THE GREATER OF;
            <br />
            <br /> (A) THE TOTAL AMOUNT PAYABLE BY THE USER TO Jobweaver.ai FOR THE
            TWELVE (12) MONTH PERIOD PRECEDING THE DATE THE CAUSE OF ACTION
            FIRST AROSE; OR <br />
            <br />
            (B) FIVE HUNDRED DOLLARS ($500). THE LIMITATIONS OF THIS SECTION
            WILL NOT APPLY TO ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED
            BY LAW.
          </p>
          <p className="para">
            Our licensors and service providers will have no liability of any
            kind under this Agreement. You may not bring any claims under this
            Agreement more than twelve (12) months after the cause of action
            arises.
          </p>
          <p className="subtitle">7. Intellectual Property:</p>
          <p className="para">
            With reference to Intellectual Property rights, the total site,
            including the logos, trademarks, designs, style, look and feel of
            the site are owned by or licensed to Jobweaver.ai, and they come under
            the preview of the intellectual property rights of the United States
            and foreign laws and international conventions. You agree not to
            use, copy or distribute any content in the site beyond the rights of
            an authorized user.
          </p>
          <p className="subtitle">8. Indemnification:</p>
          <p className="para">
            You agree to defend and indemnify the Jobweaver.ais from and against
            any third-party claim, including reasonable attorney’s fees, court
            costs, settlements, and disbursements, from or relating to:
          </p>
          <ul className="list">
            <li> Your content;</li>{" "}
            <li>
              Your use of and access to the site in violation of this Agreement;
            </li>
            <li> Your violation of this Agreement;</li>
            <li>
              Your violation of any law, rule, or regulation, or the rights of
              any third-party, including your upload or provision of personally
              identifiable information through the site;
            </li>
            <li> Your use of any Third-Party services; and</li>
            <li>
              Claims alleging that Jobweaver.ai and the client are joint employers.
            </li>
          </ul>
          <p className="subtitle">9. Copyright and DMCA Notices:</p>
          <p className="para">
            Jobweaver.ai takes copyright infringement very seriously, and It is our
            policy to terminate the account of any user who repeatedly infringes
            copyright upon prompt notification to Jobweaver.ai by the copyright
            owner or the copyright owner’s legal agent. If your work has been
            copied and posted through our site in a way that constitutes
            copyright infringement, please provide us with the following
            information:
          </p>
          <ol className="list" style={{ listStyleType: "lower roman" }}>
            <li>
              {" "}
              An electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright interest;
            </li>
            <li>
              A description of the copyrighted work that you claim has been
              infringed;
            </li>
            <li>
              A description of the material that you claim is infringing and a
              description of the location on our site of the material that you
              claim is infringing;
            </li>
            <li>Your address, telephone number and e-mail address;</li>
            <li>
              A written statement by you that you have a good faith belief that
              the disputed use is not authorized by the copyright owner, its
              agent or the law; and
            </li>
            <li>
              A statement by you, made under penalty of perjury, that the above
              information in your notice is accurate
            </li>
          </ol>
          <p className="subtitle">10. Governing Law and Dispute Resolution:</p>
          <p className="para">
            This Agreement shall be governed by the laws of the state of Texas,
            USA., without giving effect to its principles regarding conflicts of
            law. You acknowledge that the site is of United States origin and
            agree to comply with all export laws and regulations of the United
            States. Any dispute shall be settled by Arbitration which will be
            governed by AAA’s Commercial Arbitration Rules, and each party shall
            pay its own costs and their attorney fees. Any claims brought by you
            must be bought in an individual capacity, not as a class member in
            any representative proceeding. The arbitration award shall be final
            and binding upon the parties without appeal. Notwithstanding the
            foregoing, Jobweaver.ai may seek injunctive or other equitable relief
            to protect its intellectual property rights in any court of
            competent jurisdiction.
          </p>
          <p className="subtitle">11. Severability:</p>
          <p className="para">
            If any provision of these terms shall be deemed unlawful, void or
            for any reason unenforceable, then that provision shall be deemed
            severable and shall not affect the validity and enforceability of
            any remaining provisions.
          </p>
          <p className="subtitle">12. Entire Agreement:</p>
          <p className="para">
            These terms constitute the entire agreement between you and Jobvite
            regarding your access and use of the Site, and supersede all prior
            communications or terms, whether electronic, oral or written,
            between you and us. Failure by us to enforce these terms shall not
            be construed as a waiver.
          </p>
          <p className="subtitle">13. Change of Terms or Services:</p>
          <p className="para">
            You agree that Jobweaver.ai its licensors may make changes to the
            services offered on the Site, at any time without notice, and you
            further agree that Jobweaver.ai can revise these Terms at any time
            without notice by posting updated Terms on the Site. You agree that
            your continued use of the Site after such modifications have been
            posted constitutes your acceptance of such revised Terms. You should
            check the Terms periodically for updates and changes
          </p>
          <p className="subtitle">14. Electronic Communications and Privacy:</p>
          <p className="para">
            When you visit the Site, provide content, or send e-mails to us, you
            are communicating with Jobweaver.ai electronically. You consent to
            receive communications from us electronically. Jobweaver.ai will
            communicate with you by e-mail or by posting notices on the Site.
            You agree that all agreements, notices, disclosures and other
            communications that we provide to you electronically satisfy any
            legal requirement that such communications be in writing. Any
            information about yourself that you provide while accessing the
            Site, including, without limitation, your account information, shall
            be governed by our Privacy Policy at{" "}
            <a href="/privacy-policy">
              https://www.Jobweaver.ai.com/privacy-policy/
            </a>{" "}
            The Site may automatically collect certain information from you as
            further described in our Privacy Policy.
          </p>
          <p className="subtitle">15. Force Majeure:</p>
          <p className="para">
            We will not be liable for any failure or delay in performance to the
            extent caused by any circumstances beyond our reasonable control.
          </p>
          <p className="subtitle">16. Non-enforcement: </p>
          <p className="para">
            Non-enforcement of by Jobweaver.ai of any provision is not to be deemed
            as a waiver of this Agreement or under any law under the same or
            different circumstances at any time in the future.
          </p>
          <p className="subtitle">17. Termination:</p>
          <p className="para">
            Jobweaver.ai may suspend or terminate the services or your account and
            at our sole discretion without notice. Jobweaver.ai will not have any
            liability whatsoever to you for any termination or suspension of the
            account. All the clauses of ownership of material, fees, disclaimer
            of warranty, indemnity and limitation of liability shall survive
            after suspension or termination of the account.
          </p>
        </div>
        <Footer />
      </Desktop>
      <Mobile>
        <MobileView />
      </Mobile>
    </>
  );
};

export default Terms;
