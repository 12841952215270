import React from 'react'
import './Home.css'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Section1 from '../../components/HomePageSections/Section1/Section1';
import Section2 from '../../components/HomePageSections/Section2/Section2';
import Section3 from '../../components/HomePageSections/Section3/Section3';
import Section4 from '../../components/HomePageSections/Section4/Section4';
import Section5 from '../../components/HomePageSections/Section5/Section5';
import { Desktop, Mobile } from '../responsive/responsive';
import MobileView from '../../components/MobileView/mobile';

function Home() {
  return (
    <>
    <Desktop>
      <Header />
      <div className='homepage'>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Footer/>
      </div>
      </Desktop>
      <Mobile>
      <MobileView/>
      </Mobile>
    </>
  )
}

export default Home