import axios from "axios";
import { createContext, useEffect, useState } from "react";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

export const context=createContext();
export default function ContextProvider(props){
    const [loginDetails,setLoginDetails]=useState({
        isLoggedIn:false,
        user_id:"",
        user_email:"",
        free_trial:false
    });
    const [userLogged,setUserLogged] = useState(false)  // this state is just to fetch the data

    const [formDetails,setFormDetails] = useState({
        job_title:"",
        text:'',
        type:'link',
        date_posted:[],
        exp_level:[],
        salary:[],
        job_type:[],
        work_type:[],
        under_10_applicants:false,
        in_your_network:false,
        easy_apply:false,
        hard_match_titles:[],
        exclude_titles:[],
        exclude_titles_with:[],
        only_with_titles:[]
    })
    const [addFormDetails,setAddFormDetails] = useState({
        hard_match_titles:"",
        exclude_titles:"",
        exclude_titles_with:"",
        only_with_titles:""
    })
    const [date_posted,setDate_posted] = useState(null);
    const [exp_level,setExp_level] = useState(null);
    const [salary,setSalary] = useState(null);
    const [job_type,setJob_type] = useState(null);
    const [work_type,setWork_type] = useState(null);
    const [loginModalOpen,setLoginModalOpen] = useState(false)
    const [isUserHavingCard,setIsUserHavingCard] = useState(false)
    const [userHavingPlan,setUserHavingPlan] = useState(false);
    const [userPlanDetails,setUserPlanDetails] = useState({
        plan_id:""
    });

    // fetching user payment details
    async function fetchUserCardDetails(token){
        await axios.get(`${REACT_APP_BASE_URL}/v1/get-payment-details`,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        }).then((res)=>{
            // console.log(res)
            setIsUserHavingCard(true)
        }).catch((error)=>{
            // console.log(error)
            setIsUserHavingCard(false)
        })
    }

    // fetching user plan details
    async function fetchUserPlanDetails(token){
        await axios.get(`${REACT_APP_BASE_URL}/v1/get-user-plan`,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        }).then((res)=>{
            // console.log(res)
            setUserHavingPlan(true)
            // setUserPlanDetails({plan_id:res.data?.data?.plan_id})
            setUserPlanDetails({...res.data?.data})

        }).catch((error)=>{
            // console.log(error)
            setUserHavingPlan(false)
        })
    }

    // fetch user details

    async function fetchUserDetails(user_id){
        await axios.get(`${REACT_APP_BASE_URL}/v1/user-details/${user_id}`).then((res)=>{
            // console.log(res?.data?.data)
            let user_data = res?.data?.data;
            setLoginDetails({...loginDetails,isLoggedIn:true,user_email:user_data?.email,user_id:user_data?.id,free_trial:user_data?.is_free_trial})
        }).catch((error)=>{
            console.log(error)
            
        })
    }

    useEffect( ()=>{
        if(localStorage.getItem("token")){
            setLoginModalOpen(false);
            fetchUserDetails(localStorage.getItem("user_id"))
            fetchUserCardDetails(localStorage.getItem("token"))
            fetchUserPlanDetails(localStorage.getItem("token"))
        }else{
            setLoginDetails({...loginDetails,isLoggedIn:false,user_email:"",user_id:"",free_trial:false})
        }
    },[userLogged, isUserHavingCard, userHavingPlan])

    
    return(
        <>
        <context.Provider value={{loginDetails,
            setLoginDetails,
            userLogged,
            setUserLogged,
            formDetails,
            setFormDetails,
            loginModalOpen,setLoginModalOpen,
            date_posted,setDate_posted,exp_level,setExp_level,salary,setSalary,job_type,setJob_type,work_type,setWork_type,
            isUserHavingCard,setIsUserHavingCard,userHavingPlan,userPlanDetails,setUserHavingPlan,setUserPlanDetails,
            addFormDetails,setAddFormDetails
            }}>
            {props.children}
        </context.Provider>
        </>
    )
}