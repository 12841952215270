import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import img from "../../images/review.svg"
import "./Review.css"
import { useNavigate } from "react-router-dom"
import { context } from "../../store/context"
import { useContext, useState } from "react";
import axios from "axios"
import CircularProgress from '@mui/material/CircularProgress';
import Alert from "../../modals/AlertModal/Alert"
import UpgradePlanAlertModel from "../../modals/UpgradePlanAlertModel/UpgradePlanAlertModel"
import UpgradePlan from "../../components/UpgradePlan/UpgradePlan"
import LoadingModal from "../../modals/Loading/loading"
import { Desktop, Mobile } from "../responsive/responsive"
import MobileView from "../../components/MobileView/mobile"


const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const REACT_APP_PREMIUM_PLAN_ID = process.env.REACT_APP_PREMIUM_PLAN_ID



const Review = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [alertMsg, setAlertMsg] = useState(null);
    const [upgradeMsg, setUpgradeMsg] = useState(null);



    const { setUserHavingPlan, setLoginDetails, setLoginModalOpen, loginDetails, formDetails, setFormDetails, date_posted,setDate_posted, setExp_level, setSalary, setJob_type, setWork_type, addFormDetails, setAddFormDetails, isUserHavingCard } = useContext(context);
    const token = localStorage.getItem("token")



    const handleSubmit = async () => {
        setLoading(true)
        await axios.post(`${REACT_APP_BASE_URL}/v1/submit`, {
            job_title: formDetails.job_title,
            date_posted: formDetails.date_posted,
            exp_level: formDetails.exp_level,
            salary: formDetails.salary,
            job_type: formDetails.job_type,
            work_type: formDetails.work_type,
            under_10_applicants: formDetails.under_10_applicants,
            in_your_network: formDetails.in_your_network,
            easy_apply: formDetails.easy_apply,
            hard_match_titles: addFormDetails.hard_match_titles,
            exclude_titles: addFormDetails.exclude_titles,
            exclude_titles_with: addFormDetails.exclude_titles_with,
            only_with_titles: addFormDetails.only_with_titles
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            setAlertMsg(res.data.message);
            setLoading(false)
            setFormDetails({
                job_title: "",
                type:'link',
                date_posted: "",
                exp_level: "",
                salary: "",
                job_type: "",
                work_type: "",
                under_10_applicants: false,
                in_your_network: false,
                easy_apply: false,
                hard_match_titles: [],
                exclude_titles: [],
                exclude_titles_with: [],
                only_with_titles: []
            })
            setDate_posted(null);
            setExp_level(null);
            setSalary(null);
            setJob_type(null);
            setWork_type(null);
            setAddFormDetails({
                hard_match_titles: "",
                exclude_titles: "",
                exclude_titles_with: "",
                only_with_titles: ""
            })
            navigate("/success")

        }).catch((err) => {
            if (err.response.data.message === "Your plan has expired." || err.response.data.message === "You don't have any active subscription plan. Please upgrade your plan." || err.response.data.message === "You’ve reached your limit of one search per day") {
                setUpgradeMsg(err.response.data.message );
                setUserHavingPlan(false)
                localStorage.setItem("free_trial", true);
            }else{
                setAlertMsg(err.response.data.message)
            }
            
            setLoading(false)
        })

    }

    const handleOnSubmit = () => {
        if (REACT_APP_PREMIUM_PLAN_ID) {
            handleSubscription(REACT_APP_PREMIUM_PLAN_ID)
        }
        setUpgradeMsg(null)
    }



    const handleSubscription = async (plan_id) => {
        if (!loginDetails.isLoggedIn) {
            return //setLoginModalOpen(true)
        } else if (!isUserHavingCard) {
            navigate("/payment-details", {
                state: {
                    buy_plan: true
                }
            })
        } else {
            setUpgradeMsg(null)
            setLoading(true)
            const token = localStorage.getItem("token");
            await axios.post(`${REACT_APP_BASE_URL}/v1/subscribe`, { plan_id: plan_id }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setUserHavingPlan(true)
                localStorage.setItem("free_trial", false);
                setLoginDetails({ ...loginDetails, free_trial: false })
                setLoading(false)
                setAlertMsg(res?.data?.message || 'Your plan is upgraded ')

                // navigate("/step1")
            }).catch((error) => {
                if (error.response.data.message === "User already subscribed to this plan") {
                    localStorage.setItem("free_trial", false);
                    setLoginDetails({ ...loginDetails, free_trial: false })
                    setLoading(false)
                    // navigate("/step1")
                } else {
                    setAlertMsg(error.response.data.message);
                    setLoading(false)

                }
                // setLoading2(false)
            })
        }
    }

    if (!loginDetails.isLoggedIn) {
        navigate("/");
        // setLoginModalOpen(true)
        return
    }

    return (
        <div>
            <Desktop>
            <Header />
            <div className="review-conainer">
                <p className="heading">Review & submit</p>
                <div className="banner">
                    <img src={img} alt="" />
                </div>
                <p className="sub-heading">
                Take a moment to review your details, and when you’re ready press submit                </p>
                <div className="review-form">
                    <div className="container">
                        <span className="heading">Date Posted</span>
                        <span className="details">{formDetails.date_posted}</span>
                    </div>
                    <div className="container">
                        <span className="heading">Experience Level</span>
                        <span className="details">{formDetails.exp_level}</span>
                    </div>
                    <div className="container">
                        <span className="heading">Salary</span>
                        <span className="details">{(formDetails?.salary == '' || formDetails?.salary=='NA') ? 'NA':formDetails.salary.split(',').map(number => "$" + number).join(', ')}</span>
                    </div>
                    <div className="container">
                        <span className="heading">Job Type</span>
                        <span className="details">{formDetails.job_type}</span>
                    </div>
                    <div className="container">
                        <span className="heading">Work Type</span>
                        <span className="details">{formDetails.work_type}</span>
                    </div>
                    {/* <div className="container">
                        <span className="heading">Under 10 Applicants</span>
                        <span className="details">{(formDetails.under_10_applicants) ? "Yes" : "No"}</span>
                    </div>
                    <div className="container">
                        <span className="heading">In Your Netwok</span>
                        <span className="details">{(formDetails.in_your_network) ? "Yes" : "No"}</span>
                    </div>
                    <div className="container">
                        <span className="heading">Easy Apply</span>
                        <span className="details">{(formDetails.easy_apply) ? "Yes" : "No"}</span>
                    </div> */}
                    <div className="container">
                        <span className="heading">Hard Match Titles</span>
                        <span className="details">{(addFormDetails.hard_match_titles === "") ? "NA" : addFormDetails.hard_match_titles}</span>
                    </div>
                    <div className="container">
                        <span className="heading">Exclude Titles</span>
                        <span className="details">{(addFormDetails.exclude_titles === "") ? "NA" : addFormDetails.exclude_titles}</span>
                    </div>
                    <div className="container">
                        <span className="heading">Exclude Titles With</span>
                        <span className="details">{(addFormDetails.exclude_titles_with === "") ? "NA" : addFormDetails.exclude_titles_with}</span>
                    </div>
                    <div className="container">
                        <span className="heading">Only Titles With</span>
                        <span className="details">{(addFormDetails.only_with_titles === "") ? "NA" : addFormDetails.only_with_titles}</span>
                    </div>
                </div>
                <div className="button-container">
                    <button className="back-btn" onClick={() => navigate("/step2")}>Back</button>
                    <button className="submit-btn" onClick={handleSubmit}>{(loading) ? <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> : "Submit"}</button>
                </div>
                <div className="dot-container">
                    <span className="dot1"></span>
                    <span className="dot2" style={{ backgroundColor: "#58B56F", border: "none" }}></span>
                    <span className="dot3" style={{ backgroundColor: "#58B56F", border: "none" }}></span>
                </div>
                <UpgradePlan />
            </div>
            <Footer />
            </Desktop>
            <Mobile>
                <MobileView/>
            </Mobile>
            <UpgradePlanAlertModel isOpen={!!upgradeMsg} message={upgradeMsg} type='submit' onConfirm={handleOnSubmit} onClose={() => setUpgradeMsg(null)} />

            <Alert
                isOpen={!!alertMsg}
                message={alertMsg}
                onConfirm={() => {
                    setAlertMsg(null)
                }}
                onClose={() => setAlertMsg(null)} />

            <LoadingModal isOpen={loading} />
        </div>
    )
}

export default Review