import img1 from "../../../images/section4_img1.svg"
import img2 from "../../../images/section4_img2.svg"
import img3 from "../../../images/section4_img3.svg"
import "./Section4.css"

const Section4 = () =>{
    return(
        <div className="section4-container">
           <div className="section-heading">How It Works</div> 
           <div className="card-container">
            <div className="card">
                <div className="card-banner">
                    <img src={img1} alt="" />
                </div>
                <div className="card-details">
                    <p className="card-heading">Define Your Dream Job</p>
                    <p className="card-text">Fill out a simple form with your job preferences. </p>
                </div>
            </div>
            <div className="card">
                <div className="card-banner">
                    <img src={img2} alt="" />
                </div>
                <div className="card-details">
                    <p className="card-heading">Finalize Preferences</p>
                    <p className="card-text">Review and confirm your details for Jobweaver.ai’s powerful engine.</p>
                </div>
            </div>
            <div className="card">
                <div className="card-banner">
                    <img src={img3} alt="" />
                </div>
                <div className="card-details">
                    <p className="card-heading">Job Matches Delivered</p>
                    <p className="card-text">Sit back as your curated job options arrive via email.</p>
                </div>
            </div>
           </div>
        </div>
    )
}

export default Section4