import { Link } from "react-router-dom"
import logo2 from "../../images/logoWhite.png"
import "./Footer.css"
const Footer = ({style,hasFixed=false}) =>{
    return(
        <div className={`footer-container ${hasFixed ? 'bottomFixed':''}`} style={{...style}}>
            <div className="footer-left">
                <div className="footer-left-top">
                <img src={logo2} alt="logo" onClick={()=>{window.scrollTo({top:0,left:0,behavior: "smooth"})}}/>
                </div>
                <div className="footer-left-bottom">
                    <p style={{fontSize:'14px'}}>copyright © 2024 Jobweaver.ai </p>
                </div>
            </div>
            <div className="footer-right">
            <p style={{textDecoration:"underline"}}> <a href={`mailto:hamza@Jobweaver.ai?subject=Contact Jobweaver.ai`} style={{textDecoration:"none",color:"white"}}>Contact us </a></p>
            <p>|</p>
            <p style={{textDecoration:"underline"}}><a href="/terms-of-service"   style={{textDecoration:"none",color:"white"}}>Terms</a>  </p>
            <p>|</p>
            <p style={{textDecoration:"underline"}}><a href="/privacy-policy"  style={{textDecoration:"none",color:"white"}}>Privacy policy</a> </p>
            {/* <p>|</p>
            <p style={{textDecoration:"underline"}}><a href="/cookie-policy"  style={{textDecoration:"none",color:"white"}}>Cookie policy</a></p> */}
            </div>
        </div>
    )
}

export default Footer