import { useState } from 'react';
import './UpgradePlanAlertModel.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from '@mui/material';
import checkMark from '../../images/checkmark.png'

const UpgradePlanAlertModel = ({ isOpen, message=null, onClose, onConfirm, style = {},loading }) => {

  if (!isOpen) return null
  return (
    <div className='up_plan_alert' style={{ ...style }}>
      <div className='up_plan_alert-box'>
      {message && <p className='alert-msg'>{message}</p>}
      <div className='plan-details-box'>
          <p className='subheading'>Upgrade to</p>
          <p className='heading'>Premium Membership</p>
          <div className='plan-detail'>
            <img src={checkMark} alt="" />
            <p className='text'>Unlimited Searches</p>
          </div>
          <div className='plan-detail'>
            <img src={checkMark} alt="" />
            <p className='text'>Early Access to AI</p>
          </div>
          <div className='plan-detail'>
            <img src={checkMark} alt="" />
            <p className='text'>$5/month</p>
          </div>
      </div>
      <div className='btn-container'>
        <button className='upgrade' disabled={loading} onClick={onConfirm}>
      {(loading) ? <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> : 'Upgrade to Premium'} 
        </button>
        <button className='cancel' onClick={onClose}>
        Maybe later
        </button>
      </div>
      </div>
      
    </div>
  )
}

export default UpgradePlanAlertModel