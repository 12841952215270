import "./verify.css";
import cross_icon from "../../images/cross_icon.svg";
import OtpInput from "react-otp-input";
import CircularProgress from '@mui/material/CircularProgress';
const VerifyModal = ({ isOpen, otp, setOtp, email, onClose, onDone, btnLoading, resendOtp, otpType, onError }) => {
  if (!isOpen) return null;
  return (
    <div className="verify-container">
      <div className="verify-container-inner">

        <div className="close-container">
          <img src={cross_icon} alt="" onClick={onClose} />
        </div>
        <div className="text-container">
          <p className="headline">{(otpType === "verification") ? 'Check your email for your verification code':'Check your email for your reset code'}</p>
          
            {(otpType === "verification") ?<p className="sub-heading"> Your account verification email has been sent to <span style={{fontWeight:'bold'}}>{email || 'email@email.com'}</span> </p> : <p className="sub-heading">Your  password reset code has been sent to <span style={{fontWeight:'bold'}}>{email}</span></p>}
          
        </div>
        <div className="code-input-container">
          <p className="heading">{(otpType === "verification") ? `Enter verification code` : `Enter reset code`}</p>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={5}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            inputType="text"
            containerStyle={{ display: "block" }}
            inputStyle={{
              width: "30px",
              height: "40px",
              backgroundColor: "white",
              borderRadius: "8px",
              border: "1px solid #999999",
              fontSize: "18px",
              fontWeight: "600",
            }}
          />
        </div>
        {onError ? <p className="errorMsg  " style={{ width: '80%', margin: 'auto', textAlign: 'center', marginTop: '5px' }}>{onError?.error}</p> : ''}

        <div className="button-container">
          <button onClick={onDone}>{(btnLoading) ? <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> : (otpType === "verification") ? "Verify Account" : "Verify code"}</button>
        </div>
        {
          (otpType === "verification") ? <p className="regulation">
            By creating an account, I agree to Jobweaver.ai's
            <a
              href="/terms-of-service"
              style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline" }}
            >
              {" "}
              Terms
            </a>{" "}
             and{" "}
            <a
              href="/privacy-policy"
              style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline" }}
            >
              {" "}
              Privacy policy
            </a>
            {/* , and{" "}
            <a
              href="/cookie-policy"
              style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline" }}
            >
              Cookie Policy.
            </a> */}
          </p> : ""
        }

        <p className="resend-code"
        style={{paddingTop:(otpType === "verification") ? '':'20px'}}
        >
          Didn’t get it?{" "}
          <span
            style={{
              color: "rgba(48, 98, 76, 1)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={resendOtp}
          >
            Resend code
          </span>{" "}
        </p>
      </div>
    </div>

  );
};

export default VerifyModal;
