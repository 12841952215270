import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MobileView from "../../components/MobileView/mobile";
import { Desktop, Mobile } from "../responsive/responsive";
import "./Privacy.css";

const Privacy = () => {
  return (
    <>
    <Desktop>
      <Header />
      <div className="privacy-container">
        <h1 className="title">Privacy Policy</h1>
        <p className="para">
        This Privacy Policy discloses the privacy practices of [Jobweaver.ai], LLC. (“[Jobweaver.ai]”) and the possible uses of the information that we gather via our sites, our mobile application(s), and via other submissions by you to [Jobweaver.ai] (together, the "Site and Services"). By using the Site and Services you consent to the terms of this Privacy Policy. If you do not agree to the terms and conditions of this Privacy Policy, including having your personally identifiable information ("Personal Information" as defined below) used in any of the ways described in this Privacy Policy, you may not be able to use certain parts or features of our Site and Services, and in some instances, this may necessitate the revocation of your membership. [Jobweaver.ai] may update this Privacy Policy from time-to-time in our sole discretion. It is your responsibility to review the Privacy Policy for any changes each time that you use the Site and Services and you are bound by such changes. Your use of the Site and Services following us posting a new privacy policy on our Site signifies that you agree to and accept the Privacy Policy as modified.
        </p>
        <p className="para">
        This Privacy Policy applies to the entire [Jobweaver.ai] family of products and services. This Privacy Policy is part of our Terms of Use, and all other terms of using our Site and Services.
        </p>
    <p className="subtitle">Information Collected</p>
        <p className="para">
        [Jobweaver.ai] collects information on our users in various ways, such as, by your voluntary submissions, participation in services provided via the Site and Services, from third parties with your consent, and through cookie and other tracking technology. [Jobweaver.ai] collects the following information:
        </p>
        <p className="subtitle">⦁ Personal Information</p>
        <p className="para">
        "Personal Information" refers to information that tells us specifically who you are, such as your name, phone number, email, postal address, and possibly information relating to certain support or customer service issues. In many cases, we need this information to provide to you many of the services that you may have requested.
        </p>

        <p className="subtitle">⦁ Session Data/Visit Recording</p>
        <p className="para">
        By visiting the [Jobweaver.ai] Site and Services, you are agreeing that all your actions taken while visiting the Site and Services may be recorded by [Jobweaver.ai] and provided to third parties and partners. These actions may include but are not limited to filling out webforms and accepting terms and conditions and providing consent to be contacted.
        </p>
      
        <h2 className="subtitle">⦁ Submission of Reviews, Ratings, and Comments</h2>
        <p>
        We collect information that you voluntarily provide as part of our Site and Services, including messages, posts, comments, responses, reviews, and ratings you submit through the Site and Services, ("User Generated Content"). [Jobweaver.ai] may in our sole discretion share your User Generated Content with others such as your business reviews. If you do not want certain information shared with others, you should not submit User Generated Content, or should not submit User Generated Content that contains information or Personal Information you do not want shared in this manner. Once You have submitted User Generated Content, we reserve the right to publish it in any medium to others.
        </p>

        <h2 className="subtitle">⦁ Aggregate Information</h2>
        <p className="para">
        We may collect general, non-personal, statistical information about the use of the Site and Services, such as how many visitors visit a specific page on the Site, how long they stay on that page, and which hyperlinks, if any, they click on. This information represents a generic overview of our users, including their collective viewing habits, and allows us and other third parties to modify information, promotions, offers and/or discounts on products and services based on user traffic and behavior. We collect this information through the use of technologies such as 'cookies' and web beacons, which are discussed in greater detail below. We collect this information in order to help enhance the Site and Services to make for a better visitor experience.
        </p>

        <h2 className="subtitle">⦁ Third Party Information</h2>
        <p className="para">
        We may supplement information you provide to us with information from other sources, such as information to validate or update your address, payment card or other demographic and lifestyle information. We use this information to help us maintain the accuracy of the information we collect, to enable us to fulfill our products and services, to target our communications so that we can inform you of products, services and offers that may be of interest, and for internal business analysis or other business purposes consistent with our mission.
        </p>
        <h2 className="subtitle">⦁ Cookies</h2>
        <p className="para">[Jobweaver.ai] may automatically collect non-personally identifiable information and data through the use of cookies. Cookies are small text files a website uses to recognize repeat users, facilitate the user's ongoing access to the website and facilitate the use of the website. Cookies also allow a website to track usage behavior and compile site usage information that will allow for the improvement of content and target advertising. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them. You may set your browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser. However, please be aware that some features of our Site and Services may not function or may be slower if you refuse cookies. You may also get cookies from our advertisers or other third parties with links on the Site as described below. We do not directly control these cookies. The use of advertising cookies sent by third party ad servers is standard in the Internet industry.</p>
        <p className="para">We may use the services of third parties to collect and use anonymous information about your visits to and interactions with our website through the use of technologies such as cookies to personalize advertisements for goods and services. To learn more, or to opt-out of receiving online display advertisements tailored to your interests by our third party partners, visit the Digital Advertising Alliance Consumer Choice Page at <a href="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer" >http://www.aboutads.info/choices/.</a></p>
        <h2 className="subtitle">⦁  Ad Choices</h2>
        <p className="para">You may choose whether to receive some Interest-based Advertising by submitting opt-outs. Some of the advertisers that perform advertising-related services for us and third parties may participate in the Digital Advertising Alliance’s (“DAA”) Self-Regulatory Program for Online Behavioral Advertising. To learn more about how you can exercise certain choices regarding Interest-based Advertising, including use of Cross-device Data for serving ads, visit http://www.aboutads.info/choices/, and http://www.aboutads.info/appchoices for information on the DAA’s opt-out program specifically for mobile apps (including use of precise location for third party ads). Some of these companies may also be members of the Network Advertising Initiative (“NAI”). To learn more about the NAI and your opt-out options for their members, see <a href="http://www.networkadvertising.org/choices/" target="_blank" rel="noreferrer" >http://www.networkadvertising.org/choices/</a>. Please be aware that, even if you are able to opt out of certain kinds of Interest-based Advertising, you may continue to receive other types of ads. Opting out only means that those selected members should no longer deliver certain Interest-based Advertising to you but does not mean you will no longer receive any targeted content and/or ads (e.g., from other ad networks). Also, if your browsers are configured to reject cookies when you visit these opt-out webpages, or you subsequently erase your cookies, use a different device or web browser or use a non-browser-based method of access (e.g., mobile app), your NAI / DAA browser-based opt-out may not, or may no longer, be effective.</p>
        <h2 className="subtitle">⦁  Other Tracking Technologies</h2>
        <p className="para">Tracking technologies may record information such as Internet domain and host names; protocol (IP) addresses; browser software and operating system types; clickstream patterns; the MAC address or other technical information from your mobile device; location information through GPS, WiFi, Bluetooth, or similar technologies; and dates and times that our Site and Services are accessed. An IP address is a number that is automatically assigned to your computer whenever you are surfing the web. Web servers, the computers that 'serve up' webpages, automatically identify your computer by its IP address. The Site and Services may also use technology called "tracer tags". These may also be referred to as "Pixels", "Clear GIFs" or "Web Beacons". This technology allows us to understand which pages you visit on our Site and other ways you interact with our Site and Services, such as purchases made through the Site and Services. These tracer tags are used to help us to optimize and tailor our Site and Services for you and other users of our Site and Services. We may link the information we record using tracking technologies to Personal Information we collect.
</p>
<h2 className="subtitle">⦁  Do Not Track
</h2>
<p className="para">Do Not Track (DNT) is a privacy preference that users can set in some web browsers, allowing users to opt out of tracking by websites and online services. At the present time, the World Wide Web Consortium (W3C) has not yet established universal standards for recognizable DNT signals and therefore, [Jobweaver.ai] and the Site do not recognize DNT.
</p>
<p className="para">Use of Information
</p>

<p className="para">The information Jobweaver.ai collects is used in a variety of ways, including</p>
<ul className="list">
  <li>For internal review;
</li>
<li>
To improve the Site and Services;
</li>
<li>
To optimize third-party offers of products and/or services;
</li>
<li> To verify the legitimacy of reviews and ratings;
</li>
<li>
To notify you about updates to the Site and Services;
</li>
<li>
To let you know about products, services, and promotions that you may be interested in;
</li>
<li>
To get reviews and ratings;
</li>
<li>
For our marketing purposes related to services offered through our Site and Services;
</li>
<li>
To fulfill and provide products and services, including personalized or enhanced services, requested by you;
</li>
<li>
Internal business analysis or other business purposes consistent with our mission; and
</li>
<li>
To carry out other purposes that are disclosed to you and to which you consent.
</li>
</ul>

<p className="para">Disclosures and Transfers of Information</p>
<p className="para">We do not disclose Personal Information to third parties, except when one or more of the following conditions is true:</p>
<ul className="list">
  <li> We have your permission to make the disclosure;
</li>
<li>
The disclosure is necessary for the purpose for which the personal information was obtained;
</li>
<li>
The disclosure is to financial service providers in order to fulfill and carry out the purchase and provision of goods and services requested by you;

</li>
<li>
The disclosure is permitted by relevant law;
</li>
<li>
The Personal Information to be disclosed is otherwise publicly available in accordance with the applicable law;
</li>
<li>
The disclosure is reasonably related to the sale or other disposition of all or part of our business or assets;
</li>
<li>
The disclosure is for our own marketing purposes, or, with your authorization, for the marketing purposes of third parties;
</li>
<li>
The disclosure is combined with information collected from other companies and used to improve and personalize services, content, and advertising from us or third parties;
</li>
<li>
The party to whom the disclosure is made controls, is controlled by, or is under common control with [Jobweaver.ai];
</li>
<li>
The disclosure is in our sole discretion necessary for the establishment or maintenance of legal claims or legal compliance, to satisfy any law, regulation, subpoena or government request, or in connection with litigation;
</li>
<li>
The disclosure is in our sole discretion about users who we believe are engaged in illegal activities or are otherwise in violation of our [Jobweaver.ai] Terms of Service, even without a subpoena, warrant or court order; or
</li>
<li>
The disclosure is to outside businesses to perform certain services for us, such as maintaining our Site and Services, mailing lists, processing orders and delivering products and services, sending postal mail, processing claims for lost or stolen certificates, providing marketing assistance, confirming your identity for review integrity, and data analysis ("Administrative Service Providers"), including Administrative Service Providers outside the country or jurisdiction in which you reside.
</li>
</ul>
<p className="para">
Prior to sharing of your Personal Information with others, agreements are made between [Jobweaver.ai] and such outside parties to whom we are transferring data to provide for the allowable use and security of the transferred information in accordance with this Privacy Policy and applicable law. If [Jobweaver.ai] becomes aware that any such outside party is improperly using or disclosing Personal Information, it will notify that outside party and request that it take reasonable steps to cease such use or disclosure.
</p>
<p className="para">
We may provide certain Personal Information as a service to others for their marketing purposes. If you properly notify us that you no longer want us to share your Personal Information, we will cease sharing your Personal Information. However, except as otherwise stated in this Privacy Policy, we do not directly control the third parties to which we share such Personal Information and you will need to further notify such third parties directly if you would like them to cease use of your Personal Information.
</p>
<p className="para">
We may also disclose aggregate visitor data in order to describe the use of the Site and Services to our existing or potential business partners or other third parties, or in response to a government request. We may also share aggregated demographic and preference data with third parties to enable the provision of targeted information, promotions, offers and/or discounts on products and services.
</p>
<p className="subtitle">Choice: Accessing Your Information and Opt-in/Opt-out Requests
</p>
<p className="para">
To help you review, edit, and control how we use much of the information we collect about you, you can visit your account settings. On your account settings, you can update certain information and unsubscribe from receiving specific types of email messages and telephone calls, including autodialed or pre-recorded telemarketing calls placed by us or a third party on our behalf. You cannot, however, unsubscribe from receiving certain emails, such as account status and billing related emails, unless you close your account and fulfill any existing contractual obligations.
</p>
<p className="para">You can also ask us whether we are keeping information about you; ask how your information is being used; ask us to update or correct your information; or opt-out of our collection and use of certain information by sending an email to <a href={`mailto:hamza@Jobweaver.ai`}>hamza@Jobweaver.ai.</a> If you choose to opt-out of the collection and use of certain of your information, you understand that some services, may no longer be available to you, potentially requiring the cancelation of your Membership. Please state in your correspondence the details of your request. We may require proof of your identity before we provide you with the requested information. We allow you to challenge the data that we hold about you and, where appropriate, you may have certain data erased, rectified, amended, or completed. Approved requests will be processed within ten (10) days of the date on which we receive the request.</p>
<p className="subtitle">
Data Security, Integrity, and Access
</p>
<p className="para">
The Personal Information you provide and we collect is stored within databases that we control directly or through our Administrative Service Providers. As we deem appropriate and as required by the applicable law, we use security measures appropriate for the sensitivity of the Personal Information and consistent with industry standards, including physical, organizational and technological protections, such as firewalls, to protect your Personal Information. We also use industry standard encryption technology to protect credit card and password information. However, since no security system is impenetrable, we cannot guarantee the security of our databases, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet.
</p>
<p className="para">
Personal Information may only be accessed by persons within our organization, our third party service providers who require such access to provide you with the services indicated above, or any other party that you have provided us with authorization to share such information with.
We retain Personal Information that we collect only as long as reasonably necessary to fulfill the purposes for which it was collected or to otherwise meet any legal requirements.
</p>
<p className="para">
Your information may be transferred to, and maintained on, computers located outside of your state, province/territory, or country where the privacy laws may not be as protective as those where you live. If you are located outside the United States and choose to provide information to us, please be aware that we transfer Personal Information to the United States and process and store it there. As a result, this information may be subject to access requests from governments, courts, law enforcement officials and national security authorities in the United States according to its laws. Subject to the applicable laws, we will use reasonable efforts to ensure that appropriate protections are in place to maintain protections on the Personal Information. By submitting your Personal Information, you consent to having your personal data transferred to, processed, and stored in the United States. [Jobweaver.ai] may assign you a user ID and a password when you as part of your participation and access to the Site and Services. Your user ID and password may only be used by you and the members of your household. You may not share your user ID and password with anyone else, and you are solely responsible for maintaining and protecting the confidentiality of your user ID and password. You are fully responsible for all activities that occur under your user ID and password. You can access and update your login information on your account settings webpage.
</p>
<p className="subtitle">Credit Card Information</p>
<p className="para">We may share credit card information with trusted third parties in order to bill you for our service. We use third parties for secure credit card transaction processing, and we send billing information to those third parties to process your orders and payments or to validate your credit card information on file. Credit card information is encrypted during transmission and whenever stored within our systems or with our vendors. We take care to store only the amount of information required to bill you for services.</p>
<p className="para">Our credit card processing vendors use security measures to protect your information both during the transaction and after it’s complete.</p>
<p className="subtitle">
Third Party Websites and Links
</p>
<p className="para">
Please note that you may have cookies placed on your computer by third party websites that refer you to the Site. Although we do not share your Personal Information with these third party websites unless you have authorized us to do so, they may be able to link certain non-personally identifiable information we transfer to them with Personal Information they previously collected from you. Please review the privacy policies of each website you visit to better understand their privacy practices. In addition, Jobweaver.ai would like to inform you that anytime you click on links (including advertising banners), which take you to third party websites, you will be subject to the third parties' privacy policies. While we support the protection of our customer's privacy on the Internet, Jobweaver.ai expressly disclaims any and all liability for the actions of third parties, including but without limitation to actions relating to the use and/or disclosure of Personal Information by third parties
</p>
<p className="para">
Our Site and Services may contain links to other sites operated by third parties. [Jobweaver.ai] does not control such other sites and is not responsible for their content, their privacy policies, or their use of Personal Information. [Jobweaver.ai]'s inclusion of such links does not, by itself, imply any endorsement of the content on such sites or of their owners or operators except as disclosed on the Site and Services. Any information submitted by you directly to these third parties is subject to that third party's privacy policy
</p>
<p className="subtitle">
Children's Privacy
</p>
<p className="para">
[Jobweaver.ai] does not intend the Site and Services to be used by individuals under the age of majority within the jurisdiction within which they reside without the supervision of a parent or guardian of majority age. [Jobweaver.ai] relies upon such parents or guardians to determine if the Site and Services is appropriate for the viewing, access, or participation by such individuals under the age of majority. If an individual under the age of majority uses the Site and Services, such individual may only use the Site and Services under the supervision of a parent or guardian of majority age.
</p>
<p className="para">
We do not seek or knowingly collect any Personal Information about children under 13 years of age. If we become aware that we have unknowingly collected Personal Information from a child under the age of 13, we will make commercially reasonable efforts to delete such information from our database.
</p>
<p className="para">If you are the parent or guardian of a minor child who has provided us with Personal Information, you may contact us using the information below to request it be deleted.</p>

<p className="subtitle">Governing Law; Visitors Residing Outside the United States</p>
<p className="para">This Privacy Policy and the privacy practices of [Jobweaver.ai] will be subject exclusively to the laws in which [Jobweaver.ai] provides its services.</p>
<p className="subtitle">Your California Privacy Rights</p>
<p className="para">This privacy policy describes how we may share your information for marketing purposes. It applies to all [Jobweaver.ai] customers, including California residents.
</p>
<p className="subtitle">
Your Nevada Privacy Rights
</p>
<p className="para">
Under Nevada law, Nevada residents may opt out of the sale of certain “covered information” collected by operators of websites or online services. We currently do not sell covered information, as “sale” is defined by such law, and we don’t have plans to sell this information. However, if you would like to be notified if we decide in the future to sell personal information covered by the Act, please contact <a href={`mailto:hamza@Jobweaver.ai`}>hamza@Jobweaver.ai </a>to provide your name and email address. You are responsible for updating any change in your email address by the same method and we are not obligated to cross-reference other emails you may have otherwise provided us for other purposes. We will maintain this information and contact you if our plans change. At that time we will create a process for verifying your identity and providing an opportunity to verified consumers to complete their opt-out. Please become familiar with our data practices as set forth in this privacy policy. We may share your data as explained in this privacy policy, such as to enhance your experiences and our services, and those activities will be unaffected by a Nevada do not sell request. You may also have other choices regarding our data practices as set forth elsewhere in this privacy policy.
</p>
<p className="para">
You may contact us with any questions, or to request a list of third parties to whom we may disclose information for marketing purposes and the categories of information we may disclose. See below: How to Contact us.
</p>
<p className="subtitle">
How to Contact Us
</p>
<p className="para">All requests, questions, concerns, or complaints about your Personal Information or this Privacy Policy, please contact our Privacy Officer by email at the following: <a href={`mailto:hamza@Jobweaver.ai`}>hamza@Jobweaver.ai</a>
</p>
<p className="subtitle">
California Privacy Notice
</p>
<p className="para">
This California Consumer Act Privacy Notice (“CCPA Notice”) applies to “Consumers” as defined by the California Consumer Privacy Act (“CCPA”). For the purpose of this CCPA Notice, personal information applies to “Personal Information” as defined by the CCPA (also referred to herein as “PI”).
</p>
<p className="para">
  
[Jobweaver.ai] collects and shares the following categories of PI from the corresponding sources and for the corresponding purposes set forth in the table below.

</p>

<p className="para">
Category of Personal Information    Sources of Personal Information    Purposes for Collection    Categories of Third Parties with whom Personal Information is shared    Purposes of Third Parties Receiving PI
</p>
<p className="subtitle">
1. Identifiers
</p>
<p className="para">
(e.g. Name, Phone Number, Email Address, I.P. Address, Account Name)    Directly from you; authentication partners; Payment Vendors    Delivering services requested by you; processing and managing interactions and transactions; Research and Development; quality assurance; improving [Jobweaver.ai]’s services; security; fraud prevention; marketing; debugging    Service Professionals; Vendors which assist us in providing services and running our internal business operations (“Vendors”)    Performing services on behalf of [Jobweaver.ai]; processing and managing interactions and transactions; Research and Development; quality assurance; improving [Jobweaver.ai]’s services; security; fraud prevention; marketing; debugging
</p>
<p className="subtitle">
2. Personal Records
</p>
<p className="para">
(e.g. Name, Phone Number, Email Address)    Directly from you; authentication partners; Payment Vendors    Delivering services requested by you; processing and managing interactions and transactions; Research and Development; quality assurance; improving [Jobweaver.ai]'s services; security; fraud prevention; marketing; debugging    Service Professionals; Vendors    Performing services on behalf of [Jobweaver.ai]; processing and managing interactions and transactions; Research and Development; quality assurance; improving [Jobweaver.ai]'s services; security; fraud prevention; marketing; debugging
</p>
<p className="subtitle">
3. Consumer Characteristics
</p>
<p className="para">
Data Enrichment Tools    Delivering services requested by you; marketing    Vendors    Delivering services requested by you; marketing
</p>
<p className="subtitle">
4. Customer Account Details / Commercial Information
</p>
<p className="para">
(e.g. details of service requests)    Directly from you; Data Enrichment Tools; Payment Vendors    Delivering services requested by you; processing and managing interactions and transactions; Research and Development; quality assurance; improving [Jobweaver.ai]'s services; security; fraud prevention; marketing; debugging    Service Professionals; Vendors    Delivering services requested by you; processing and managing interactions and transactions; Research and Development; quality assurance; improving [Jobweaver.ai]'s services; security; fraud prevention; marketing; debugging
</p>
<p className="subtitle">
5. Internet Usage Information
</p>
<p className="para">
(e.g. information regarding your interaction with our services)    Directly from you; analytics partners; advertising partners    Delivering services requested by you; processing and managing interactions and transactions; Research and Development; quality assurance; improving [Jobweaver.ai]’s services; security; fraud prevention; marketing; debugging measuring advertising efficacy    Vendors    Delivering services requested by you; processing and managing interactions and transactions; Research and Development; quality assurance; improving [Jobweaver.ai]'s services; security; fraud prevention; marketing; debugging
</p>
<p className="subtitle">
6. Sensory Data
</p>
<p className="para">
(e.g. recordings of customer service calls)    Directly from you    Delivering services requested by you; processing and managing interactions and transactions; Research and Development; quality assurance; improving [Jobweaver.ai]’s services; security; fraud prevention    Vendors    Delivering services requested by you; processing and managing interactions and transactions; Research and Development; quality assurance; security; fraud prevention
</p>
<p className="subtitle">7. Inferences from PI Collected</p>
<p className="para">
(e.g. your preferences, likelihood of interest in certain of our services)    Data Management providers; advertising partners; internal systems    Delivering services requested by you; Measuring advertising efficacy; marketing; improving [Jobweaver.ai]'s services    Vendors    Performing services on behalf of [Jobweaver.ai]; processing and managing interactions and transactions; quality assurance; improving [Jobweaver.ai] services; security; fraud prevention; marketing; debugging
</p>
<p className="para">
In addition, we may collect, use and disclose your PI as required or permitted by applicable law, or as directed by you, in accordance with this Privacy Policy.
</p>
<p className="para">
We do not “sell” personal information that we collect from you, in accordance with the definition of “sell” in the CCPA, and will treat personal information we collect from you as subject to a do not sell request. There is not yet a consensus as to whether third party cookies and tracking devices associated with our websites and mobile apps may constitute a “sale” of your PI as defined by the CCPA. You can exercise control over browser-based cookies by adjusting the settings on your browser.
</p>
<p className="para">
Some browsers have signals that may be characterized as do not track signals, but we do not understand them to operate in that manner or to indicate a do not sell expression by you so we currently do not recognize these as a do not sell request. We understand that various parties are developing do not sell signals and we may recognize certain such signals if we conclude such a program is appropriate.
</p>
<p className="para">
California Consumers have the right to exercise the privacy rights under the CCPA. California Consumers may exercise these rights via an authorized agent who meets the agency requirements of the CCPA. Any request you submit to us is subject to an identification and residency verification process (“Verifiable Consumer Request”). We will not fulfill your CCPA request unless you have provided sufficient information for us to reasonably verify you are the Consumer about whom we collected PI. Please follow the instructions below and respond to any follow up inquires we may make.
</p>
<p className="para">
Some personal information we maintain about Consumers is not sufficiently associated with enough personal information about the Consumer for us to be able to verify that it is a particular Consumer’s personal information (e.g., clickstream data tied only to a pseudonymous browser ID). As required by the CCPA, we do not include that personal information in response to Verifiable Consumer Requests. If we cannot comply with a request, we will explain the reasons in our response.
</p>
<p className="para">
We will make commercially reasonable efforts to identify Consumer PI that we collect, process, store, disclose, and otherwise use and to respond to your California Consumer privacy rights requests. We will typically not charge a fee to fully respond to your requests, but we may charge a reasonable fee, or refuse to act upon a request, if your request is excessive, repetitive, unfounded, or overly burdensome.
</p>
<p className="para">
To make a request according to your right to request deletion of your PI set forth below, you may send a request by email (including the email address that you have used to transact with [Jobweaver.ai] to <a href={`mailto:hamza@Jobweaver.ai`}>hamza@Jobweaver.ai.</a>)
</p>
<p className="para">
You have the right to send us a request, no more than twice in a twelve-month period, for any of the following for the period that is twelve months prior to the request date, by sending a request by email (including the email address that you have used to transact with [Jobweaver.ai] to <a href={`mailto:hamza@Jobweaver.ai`}>hamza@Jobweaver.ai</a>)
</p>
<ul className="list">
  <li>  The categories of PI we have collected about you.
</li>
  <li>The categories of sources from which we collected your PI.
</li>
  <li> The business or commercial purposes for our collecting or selling your PI.</li>
  <li>    The categories of third parties to whom we have shared your PI.
</li>
  <li>The specific pieces of PI we have collected about you.
</li>
  <li> A list of the categories of PI disclosed for a business purpose in the prior 12 months, or that no disclosure occurred.</li>
  <li> A list of the categories of PI sold about you in the prior 12 months, or that no sale occurred. If we sold your PI, we will explain:</li>
  <li> The categories of your PI we have sold.
</li>
  <li>The categories of third parties to which we sold PI, by categories of PI sold for each third party.
</li>
</ul>
<p className="para">
You have the right to make or obtain a transportable copy, no more than twice in a twelve-month period, of your PI that we have collected in the period that is 12 months prior to the request date and are maintaining, by sending a request by email (including the email address that you have used to transact with [Jobweaver.ai]) to <a href={`mailto:hamza@Jobweaver.ai`}>hamza@Jobweaver.ai</a>.
</p>
<p className="para">
  
Please note that PI is retained by us for various time periods, so we may not be able to fully respond to what might be relevant going back 12 months prior to the request.

</p>
<p className="para">
Except to the extent we have a basis for retention under CCPA, you may request that we delete your PI that we have collected directly from you and are maintaining. Note also that we are not required to delete your PI that we did not collect directly from you.
</p>
<p className="para">
We will not discriminate against you in a manner prohibited by the CCPA because you exercise your CCPA rights. However, we may charge a different price or rate, or offer a different level or quality of good or service, to the extent that doing so is reasonably related to the value of the applicable data. In addition, we may offer you financial incentives for the collection, sale and retention and use of your PI as permitted by the CCPA that can, without limitation, result in reasonably different prices, rates, or quality levels. The material aspects of any financial incentive will be explained and described in its program terms. Please note that participating in incentive programs is entirely optional, you will have to affirmatively opt-in to the program and you can opt-out of each program (i.e., terminate participation and forgo the ongoing incentives) prospectively by following the instructions in the applicable program description and terms. We may add or change incentive programs and/or their terms by posting notice on the program descriptions and terms linked to above so check them regularly.
</p>
      </div>
      <Footer />
    </Desktop>
    <Mobile>
      <MobileView/>
    </Mobile>
    </>
    
  );
};

export default Privacy;
