import cross_icon from "../../images/cross_icon.svg"
import "./AdditionalForm.css";
import { context } from "../../store/context";
import { useContext, useState } from "react";
import TagInput from "../../components/TagInput/TagInput";

const AdditionalForm = ({isOpen,onClose}) =>{
    const {formDetails,setFormDetails,addFormDetails,setAddFormDetails} = useContext(context);

    return (
        
        <div  className={`${isOpen ? 'ad-form-container active' : 'ad-form-container'}`}
        >

        <div className="cross-icon">
        <img src={cross_icon} alt="" onClick={onClose} style={{cursor:"pointer"}}/>
        </div> 
        {/* <div className="top-container">
        <div className="switch-container">
            <p>Under 10 Applicants</p>
            <div>
            <label className="switch">
             <input type="checkbox" checked={formDetails.under_10_applicants} onChange={()=>setFormDetails({...formDetails,under_10_applicants:!formDetails.under_10_applicants})}/>
             <span className="sliders round"></span>
             </label>
            </div>
        </div>
        <div className="switch-container">
            <p>In Your Network</p>
            <div>
            <label className="switch">
             <input type="checkbox" checked={formDetails.in_your_network} onChange={()=>setFormDetails({...formDetails,in_your_network:!formDetails.in_your_network})}/>
             <span className="sliders round"></span>
             </label>
            </div>
        </div>
        <div className="switch-container">
            <p>Easy Apply Toggle</p>
            <div>
            <label className="switch">
             <input type="checkbox" checked={formDetails.easy_apply} onChange={()=>setFormDetails({...formDetails,easy_apply:!formDetails.easy_apply})}/>
             <span className="sliders round"></span>
             </label>
            </div>
        </div>
        </div>
        <div className="divider"></div> */}
        <div className="addtional-form-container">
            <div className="input-container">
            <label>Hard Match Titles</label>
            {/* <input type="text" placeholder="Separate with commas" value={formDetails.hard_match_titles} onChange={(e)=>{setFormDetails({...formDetails,hard_match_titles:e.target.value})}}/> */}
            <TagInput
             tags={formDetails.hard_match_titles}
             clearTags={false}
             handleChange={(inputs)=>{
                setFormDetails({...formDetails,hard_match_titles:[...inputs]})
                if(inputs.length > 0){

                    
                    setAddFormDetails({...addFormDetails,hard_match_titles:inputs.join(",")})
                }else{
                    setAddFormDetails({...addFormDetails,hard_match_titles:""})
                }
            }}
            placeholder={formDetails.hard_match_titles.length > 0 ? '':"Separate with commas"}
            />
            </div> 
        </div>
        <div className="divider"></div>
        <div className="addtional-form-container">
            <div className="input-container">
            <label>Exclude Titles</label>
            {/* <input type="text" placeholder="Separate with commas" value={formDetails.exclude_titles} onChange={(e)=>{setFormDetails({...formDetails,exclude_titles:e.target.value})}}/> */}
            <TagInput
             tags={formDetails.exclude_titles}
             clearTags={false}
             handleChange={(inputs)=>{
                setFormDetails({...formDetails,exclude_titles:[...inputs]})
                if(inputs.length > 0){

                    
                    setAddFormDetails({...addFormDetails,exclude_titles:inputs.join(",")})
                }else{
                    setAddFormDetails({...addFormDetails,exclude_titles:""})
                }
            }}
            placeholder={formDetails.exclude_titles.length > 0 ? '':"Separate with commas"}
            />
            </div>
             
        </div>
        <div className="divider"></div>
        <div className="addtional-form-container">
            <div className="input-container">
            <label>Exclude Title With</label>
            {/* <input type="text" placeholder="Separate with commas" value={formDetails.exclude_titles_with} onChange={(e)=>{setFormDetails({...formDetails,exclude_titles_with:e.target.value})}}/> */}
            <TagInput
             tags={formDetails.exclude_titles_with}
             clearTags={false}
             handleChange={(inputs)=>{
                setFormDetails({...formDetails,exclude_titles_with:[...inputs]})
                if(inputs.length > 0){                  
                    setAddFormDetails({...addFormDetails,exclude_titles_with:inputs.join(",")})
                }else{
                    setAddFormDetails({...addFormDetails,exclude_titles_with:""})
                }
            }}
            placeholder={formDetails.exclude_titles_with.length > 0 ? '':"Separate with commas"}
            />
            </div>
        </div>
        <div className="divider"></div>
        <div className="addtional-form-container">
            <div className="input-container">
            <label>Only Titles With</label>
            {/* <input type="text" placeholder="Separate with commas" value={formDetails.only_with_titles} onChange={(e)=>{setFormDetails({...formDetails,only_with_titles:e.target.value})}}/> */}
            <TagInput
             tags={formDetails.only_with_titles}
             clearTags={false}
             handleChange={(inputs)=>{
                setFormDetails({...formDetails,only_with_titles:[...inputs]})
                if(inputs.length > 0){
                    setAddFormDetails({...addFormDetails,only_with_titles:inputs.join(",")})
                }else{
                    setAddFormDetails({...addFormDetails,only_with_titles:""})
                }
            }}
            placeholder={formDetails.only_with_titles.length > 0 ? '':"Separate with commas"}
            />
            </div>
             
        </div>
        </div>
    )
}

export default AdditionalForm