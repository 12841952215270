import { useState, useEffect, useContext } from "react"
import logo from "../../images/logoDark.png"
import "./Header.css"
import SignUp from "../../modals/signUp/SignUp"
import PasswordModal from "../../modals/password/password"
import VerifyModal from "../../modals/verifyModal/verify"
import axios from "axios"
import { validEmail } from "../../utils/regex"
import LoadingModal from "../../modals/Loading/loading"
import Login from "../../modals/Login/Login"
import { context } from "../../store/context"
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router"
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Alert from "../../modals/AlertModal/Alert"
import RecoverPassword from "../../modals/RecoverPassword/Recover"
import ChangePassword from "../../modals/changePassword/changePassword"
import DowngGradePlanAlertModel from "../../modals/DownGradePlanAlert/DownGradePlanAlert"
import person from '../../images/person.svg';
import dropDown from '../../images/downArrow.svg';
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const Header = () => {

    const { setUserPlanDetails,setUserHavingPlan,loginDetails, setLoginDetails, setUserLogged, userLogged, loginModalOpen, setLoginModalOpen,setFormDetails, setDate_posted, setExp_level, setSalary,  setJob_type,  setWork_type,setAddFormDetails } = useContext(context)
    const [open, setOpen] = useState(false);
    const [passOpen, setPassOpen] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [verifyOpen, setVerifyOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [verifyBtnLoading, setVerifyBtnLoading] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [showLoginPass, setShowLoginPass] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState(null);
    const [recoverOpen, setRecoverOpen] = useState(false);
    const [recoveryEmail, setRecoveryEmail] = useState("");
    const [confirmMsg, setConfirmMsg] = useState(null);
    const [otpType, setOtpType] = useState("verification");  // here there are two values "verification" and "reset-password" based on these value content in the verify modal changes and also the resend otp function
    const [resetPassConfirmMsg, setResetPassConfirmMsg] = useState(null);
    const [changePassOpen, setChangePassOpen] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [verificaionPendingAlert, setVerificaionPendingAlert] = useState(null)

    const [errorInPasswords, setErrorInPasswords] = useState(null)
    const [errorInSignin, setErrorInSignin] = useState(null)
    const [errorInSignup, setErrorInSignup] = useState(null)
    const [errorInRecoverPassword, setErrorInRecoverPassword] = useState(null)
    const [errorInVerify, setErrorInVerify] = useState(null)
    const [errorInChangePassword, setErrorInChangePassword] = useState(null)
    const [needEmailinAlert,setNeedEmailInAlert] = useState(false);
    const [accountConfirmAlert,setAccountConfirmAlert] = useState(null)
  const [downgradeMsg, setdowngradeMsg] = useState(null);
  const [loading1,setLoading1] = useState(false)




    const navigate = useNavigate();

    const handleDone = async (e) => {
        e.preventDefault()
        if (email === "") {
            setErrorInSignup({
                type: "email",
                error: "Email is required"
            })
        }
        else if (!validEmail.test(email)) {
            setErrorInSignup({
                type: "email",
                error: "Enter valid email"
            })
        } else {
            setBtnLoading(true)
            await axios.post(`${REACT_APP_BASE_URL}/v1/verify-user`, { email: email }).then((res) => {
                setPassOpen(true);
                setOpen(false);
                setBtnLoading(false)
            }).catch((error) => {

                setErrorInSignup({
                    type: "other",
                    error: error?.response?.data?.message
                })
                setBtnLoading(false)
            })

        }
    }
    const handleEyeClick = () => {
        setShowPass(!showPass)
    }
    const handleCreateAcccount = async () => {
        setBtnLoading(true)
        await axios.post(`${REACT_APP_BASE_URL}/v1/register`, { email: email, password: password }).then((res) => {

            setAccountConfirmAlert(res.data.message)
            setNeedEmailInAlert(true)
            // setVerifyOpen(true)
            setBtnLoading(false)
            setErrorInPasswords(null)
            setPassOpen(false)
        }).catch((error) => {
            if (error?.response?.data.message === "user is already registered, please verify your account with otp sent to your email") {
                setVerificaionPendingAlert(error?.response?.data.message)
                setNeedEmailInAlert(true)
                setBtnLoading(false);
                setErrorInPasswords(null);
                setPassOpen(false)
            } else {
                setErrorInPasswords(error.response.data.message)
                setBtnLoading(false)
            }

        })

        setBtnLoading(false)
    }
    const handleClose = () => {
        setVerifyOpen(false)
        setEmail("")
        setOtp("")
        setPassword("")
        setOtpType("verification")
        setRecoveryEmail("")
        setErrorInVerify(null);
    }

    const handleVerifyOtp = async () => {
        setVerifyBtnLoading(true)
        await axios.post(`${REACT_APP_BASE_URL}/v1/verify-otp`, { otp: otp, email: (otpType === "verification") ? (email === "") ? loginEmail : email : recoveryEmail, type: otpType }).then((res) => {
            if (otpType === "verification") {
                setAlertMsg(res.data.message);
                setOtp("");
                setEmail("");
                setPassword("");
                setVerifyOpen(false);
                setVerifyBtnLoading(false);
                setLoginEmail("");
                setLoginPassword("");
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("user_id", res.data.id);
                localStorage.setItem("user_email", res.data.email);
                localStorage.setItem("free_trial", res.data.is_free_trial);
                setLoginDetails({ ...loginDetails, free_trial: res.data.is_free_trial })
                setUserLogged(!userLogged);
                navigate("/step1")
            } else if (otpType === "reset-password") {
                setResetPassConfirmMsg(res.data.message);
                setVerifyBtnLoading(false);
                setOtp("");
                setVerifyOpen(false);
                setOtpType("verification")
            }

        }).catch((error) => {
            setErrorInVerify({
                type: "other",
                error: error?.response?.data?.message
            })
            setVerifyBtnLoading(false)
        })
    }

    const handleLogin = async () => {
        if (loginEmail === "") {
            setErrorInSignin({
                type: "email",
                error: "Email is required"
            })
            return
        } else if (!validEmail.test(loginEmail)) {
            setErrorInSignin({
                type: "email",
                error: "Enter a valid email"
            })
            return
        } else if (loginPassword === '') {
            setErrorInSignin({
                type: "password",
                error: "Password is required"
            })
            return

        } else {
            setBtnLoading(true)
            await axios.post(`${REACT_APP_BASE_URL}/v1/login`, { email: loginEmail, password: loginPassword }).then((res) => {
                setAlertMsg(res?.data?.message);
                setLoginOpen(false)
                setLoginModalOpen(false)
                setBtnLoading(false);
                setLoginEmail("");
                setLoginPassword("");
                setShowLoginPass(false)
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("user_id", res.data.id);
                localStorage.setItem("user_email", res.data.email);
                localStorage.setItem("free_trial", res.data.is_free_trial);
                setLoginDetails({ ...loginDetails, free_trial: res.data.is_free_trial })
                setUserLogged(!userLogged)
                setErrorInSignin(null)
                navigate("/step1")
            }).catch((error) => {
                if (error.response.data.message === "user verification pending, please verify your account with code sent to your email") {
                    setVerificaionPendingAlert(error.response.data.message)
                    setNeedEmailInAlert(true)
                    setLoginModalOpen(false)
                    setLoginOpen(false)
                    setBtnLoading(false)

                    setErrorInSignin(null)

                } else {
                    setErrorInSignin({
                        type: "other",
                        error: error.response.data.message
                    })

                    setBtnLoading(false)
                }
            })
        }
    }
    useEffect(() => {
        if (open || passOpen || verifyOpen || loginOpen || loginModalOpen || recoverOpen || changePassOpen) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "unset"
        }
    }, [open, passOpen, verifyOpen, loginOpen, loginModalOpen, recoverOpen, changePassOpen]);

    const handleLogOut = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("user_id")
        localStorage.removeItem("user_email")
        localStorage.removeItem("free_trial")
        localStorage.clear()
        setUserLogged(!userLogged);
        setDropDownOpen(false);
        navigate('/')
        // the below things are to reset the form values upon logout
        setFormDetails({
            job_title:"",
            text:'',
            type:'link',
            date_posted:[],
            exp_level:[],
            salary:[],
            job_type:[],
            work_type:[],
            under_10_applicants:false,
            in_your_network:false,
            easy_apply:false,
            hard_match_titles:[],
            exclude_titles:[],
            exclude_titles_with:[],
            only_with_titles:[]
        })
        setAddFormDetails({
            hard_match_titles:"",
            exclude_titles:"",
            exclude_titles_with:"",
            only_with_titles:""
        })
        setDate_posted(null); setExp_level(null); setSalary(null);  setJob_type(null);  setWork_type(null)
    }

    // google signup
    const googleSignup = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setLoading(true);
            // console.log(tokenResponse)
            await axios.post(`${REACT_APP_BASE_URL}/v1/google-login`
                , { credential: tokenResponse.access_token, type: "register" }).then((res) => {
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("user_id", res.data.id);
                    localStorage.setItem("user_email", res.data.email);
                    localStorage.setItem("free_trial", res.data.is_free_trial);
                    setLoginDetails({ ...loginDetails, free_trial: res.data.is_free_trial })
                    setUserLogged(!userLogged);
                    setLoading(false);
                    setOpen(false)
                    setEmail("");
                    navigate("/step1")
                }).catch((error) => {
                    // console.log(error)
                    setLoading(false);
                    setAlertMsg(error.response.data.message)
                })
        }
    });

    // google signIn
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setLoading(true);
            // console.log(tokenResponse)
            await axios.post(`${REACT_APP_BASE_URL}/v1/google-login`
                , { credential: tokenResponse.access_token, type: "login" }).then((res) => {
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("user_id", res.data.id);
                    localStorage.setItem("user_email", res.data.email);
                    localStorage.setItem("free_trial", res.data.is_free_trial);
                    setLoginDetails({ ...loginDetails, free_trial: res.data.is_free_trial })
                    setUserLogged(!userLogged);
                    setLoading(false);
                    setLoginOpen(false)
                    setLoginModalOpen(false);
                    navigate("/step1")
                }).catch((error) => {
                    console.log(error)
                    setAlertMsg(error.response.data.message)
                    setLoading(false);
                })
        }
    });


    // facebook signup
    const responseFacebookSignUp = async (response) => {
        if(response?.status==='unknown'){
            return
        }
        
        setLoading(true)
        await axios.post(`${REACT_APP_BASE_URL}/v1/facebook-login`, {
            email: response?.email,
            credential: response?.accessToken,
            facebook_id: response?.id,
            type: "register"
        }).then((res) => {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user_id", res.data.id);
            localStorage.setItem("user_email", res.data.email);
            localStorage.setItem("free_trial", res.data.is_free_trial);
            setLoginDetails({ ...loginDetails, free_trial: res.data.is_free_trial })
            setUserLogged(!userLogged);
            setLoading(false);
            setOpen(false);
            setEmail("");
            navigate("/step1")
        }).catch((error) => {
            console.log(error)
            setAlertMsg(error?.response?.data?.message)
            setLoading(false)
        })
    }

    // facebook login
    const responseFacebookLogin = async (response) => {
        if(response?.status==='unknown'){
            return
        }

        setLoading(true)
        await axios.post(`${REACT_APP_BASE_URL}/v1/facebook-login`, {
            email: response?.email,
            credential: response?.accessToken,
            facebook_id: response?.id,
            type: "login"
        }).then((res) => {
          
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user_id", res.data.id);
            localStorage.setItem("user_email", res.data.email);
            localStorage.setItem("free_trial", res.data.is_free_trial);
            setLoginDetails({ ...loginDetails, free_trial: res.data.is_free_trial })
            setUserLogged(!userLogged);
            setLoading(false);
            setLoginOpen(false);
            setLoginModalOpen(false);
            navigate("/step1")
        }).catch((error) => {
            console.log(error)
            setAlertMsg(error?.response?.data?.message)
            setLoading(false)
        })
    }


    // apple signup

    const responseAppleSignup = async (response) => {

        setLoading(true)
        await axios.post(`${REACT_APP_BASE_URL}/v1/apple-login`, {
            auth_code: response?.authorization?.code || null,
            email: response?.user?.email || null,
            type: "register"
        }).then((res) => {
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem("user_id", res?.data?.id);
            localStorage.setItem("user_email", res?.data?.email || null);
            localStorage.setItem("apple_user_id", res?.data?.apple_user_id || null);
            localStorage.setItem("free_trial", res?.data?.is_free_trial);
            setLoginDetails({ ...loginDetails, free_trial: res?.data?.is_free_trial })
            setUserLogged(!userLogged);
            setLoading(false);
            setOpen(false);
            setEmail("");
            navigate("/step1")
        }).catch((error) => {
            console.log(error)
            setAlertMsg(error?.response?.data?.message)
            setLoading(false)
        })
    }


    //apple login 

    const responseAppleLogin = async (response) => {

        setLoading(true)
        await axios.post(`${REACT_APP_BASE_URL}/v1/apple-login`, {
            auth_code: response.authorization.code,
            email: response?.user?.email || null,
            type: "login"
        }).then((res) => {
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem("user_id", res?.data?.id);
            localStorage.setItem("user_email", res?.data?.email || null);
            localStorage.setItem("apple_user_id", res?.data?.apple_user_id || null);
            localStorage.setItem("free_trial", res?.data?.is_free_trial);
            setLoginDetails({ ...loginDetails, free_trial: res?.data?.is_free_trial })
            setUserLogged(!userLogged);
            setLoading(false);
            setOpen(false);
            setLoginOpen(false);
            setLoginModalOpen(false);
            setEmail("");
            navigate("/step1")
        }).catch((error) => {
            console.log(error)
            setAlertMsg(error?.response?.data?.message)
            setLoading(false)
        })
    }


    // resend otp 
    const resendOtp = async () => {
        setErrorInVerify(null)
        setOtp("")
        await axios.post(`${REACT_APP_BASE_URL}/v1/resend-otp`, { email: (otpType === "verification") ? (email === "") ? loginEmail : email : recoveryEmail, type: otpType }).then((res) => {
         
            setErrorInVerify({
                type: "resend-otp",
                error: res?.data?.message
            })
        }).catch((error) => {
            console.log(error)
            setErrorInVerify({
                type: "other",
                error: error?.response?.data?.message
            })
        })
    }

    // send recovery code for reset password
    const sendRecoveryEmail = async () => {
        if (recoveryEmail === "") {
            setErrorInRecoverPassword({
                type: "email",
                error: "Email is required"
            })
        } else if (!validEmail.test(recoveryEmail)) {
            setErrorInRecoverPassword({
                type: "email",
                error: "Invalid Email"
            })
        } else {
            setBtnLoading(true)
            setOtpType("reset-password")
            await axios.post(`${REACT_APP_BASE_URL}/v1/send-recovery-code`, { email: recoveryEmail }).then((res) => {
                setBtnLoading(false)
                setConfirmMsg(res.data?.message);
                setNeedEmailInAlert(true)
                setErrorInRecoverPassword(null)
                setRecoverOpen(false)
            }).catch((error) => {
                setErrorInRecoverPassword({
                    type: "other",
                    error: error?.response?.data?.message
                })
                setBtnLoading(false)
            })
        }
    }



    // change password 
    const handleChangePassword = async () => {
        if (newPass === "") {
            setErrorInChangePassword({
                type: "other",
                error: "New password is required"
            })
            
            // return setAlertMsg("New password is required") 
        } else if (confirmPass === "") {
            setErrorInChangePassword({
                type: "other",
                error: "Confirm password is required"
            })
            
            // return setAlertMsg("Confirm password is required")
        } else if (newPass !== confirmPass) {
            setErrorInChangePassword({
                type: "other",
                error: "New password and confirm password should match"
            })
            // return setAlertMsg("New password and confirm password should match")
        } else {
            setBtnLoading(true);
            await axios.post(`${REACT_APP_BASE_URL}/v1/reset-password`, { email: recoveryEmail, password: newPass }).then((res) => {
                setBtnLoading(false);
                setChangePassOpen(false);
                setNewPass("")
                setConfirmPass("");
                setRecoveryEmail("");
                setAlertMsg(res.data?.message)
                setNeedEmailInAlert(true)
                setOtpType("verification")
                setShowPass(false)
            }).catch((error) => {
                setBtnLoading(false)
                setErrorInChangePassword({
                    type: "other",
                    error: error.response?.data?.message
                })
            })
        }
    }

    const handleDownGradeTofreeTrial =async () =>{
        setLoading1(true)
        const token = localStorage.getItem("token");
        await axios.delete(`${REACT_APP_BASE_URL}/v1/un-subscribe`,{
          headers:{
            Authorization: `Bearer ${token}`
          }
        }).then((res)=>{
          // console.log(res);
          localStorage.setItem("free_trial",true);
          setUserHavingPlan(false)
          setUserPlanDetails({})
          setLoginDetails({...loginDetails,free_trial:true})
          setdowngradeMsg('')
          setAlertMsg("You have been Unsubscribed from Premium Plan.")
  
          // navigate("/step1");
          setLoading1(false)
        }).catch((err)=>{
          // console.log(err);
          setdowngradeMsg('')
          setAlertMsg(err.response.data.message)
          setLoading1(false)
        })
    }
    return (
        <>
            {(open || passOpen || verifyOpen || loginOpen || loginModalOpen || recoverOpen || changePassOpen) ? <div className="upper-container"></div> : null}
            <div className="header-container">
                <div className="header-left">
                    <img src={logo} alt="" onClick={() => navigate("/")} />
                    {/* <p className="logo-text" onClick={()=>navigate("/")}>Logo</p> */}
                </div>
                {(!loginDetails.isLoggedIn) && 
                    <div className="header-right">
                        <button className="header-btn sign-in-btn" onClick={() => { setLoginOpen(true) }}>Sign in</button>
                        <button className="header-btn sign-up-btn" onClick={() => setOpen(true)}>Sign up</button>
                    </div> }
                    {
                        (loginDetails.isLoggedIn) && <div className="header-right" >
                        <div>
                            <button className="begin-btn" onClick={() => navigate("/step1")}>Begin Search</button>
                        </div>
                        
                        {(loginDetails.isLoggedIn) &&
                            <div className="profile-container" onClick={() => setDropDownOpen(!dropDownOpen)}>
                                {/* <AccountCircleOutlinedIcon className="avtar" style={{fontWeight:'400',opacity:'0.7'}}/> */}
                                <img src={person} alt="" className="avtar" />
                                <img src={dropDown} alt="" />
                                {/* <KeyboardArrowDownOutlinedIcon style={{ cursor: "pointer",opacity:'0.7' }} /> */}
                                {(dropDownOpen) ? <div className="drop-down-container" onMouseLeave={() => setDropDownOpen(false)}>
                                <p className="logout-btn list" >{loginDetails.user_email || `email@email.com`}</p>
                                {(loginDetails?.free_trial) ? "":<p onClick={()=>setdowngradeMsg('downGrade')} className="logout-btn list">Downgrade to Basic</p>}
                                    <p className="logout-btn list" onClick={handleLogOut} >Log out</p>
                                
                                </div> : ""}
                            </div>  }
                    </div>
                    }
                
            </div>
                <SignUp isOpen={open || loginModalOpen} onError={errorInSignup} onClose={() => { setErrorInSignin(null); setErrorInSignup(null); setErrorInPasswords(null); setOpen(false);setLoginModalOpen(false); setEmail("") }} onDone={handleDone} email={email} setEmail={setEmail} btnLoading={btnLoading} googleLogin={googleSignup} facebookSignUp={responseFacebookSignUp} appleSignUp={responseAppleSignup} login={()=>{setLoginModalOpen(false);setOpen(false);setLoginOpen(true)}}/>
                <PasswordModal isOpen={passOpen} onError={errorInPasswords} onClose={() => { setErrorInSignin(null); setErrorInSignup(null); setErrorInPasswords(null); setPassOpen(false); setPassword(""); setEmail(""); setShowPass(false) }} showPass={showPass} onClickEye={handleEyeClick} email={email} pass={password} setPass={setPassword} onDone={handleCreateAcccount} btnLoading={btnLoading} onClickRegister={() => { setPassOpen(false); setOpen(true); setEmail(""); setPassword("") }} />
                <VerifyModal isOpen={verifyOpen} onError={errorInVerify} otp={otp} setOtp={setOtp} onClose={() => { handleClose() }} email={(otpType === "verification") ? (email === "") ? loginEmail : email : recoveryEmail} onDone={handleVerifyOtp} btnLoading={verifyBtnLoading} resendOtp={resendOtp} otpType={otpType} />
                <LoadingModal isOpen={loading || loading1} />
                <Login isOpen={loginOpen } onError={errorInSignin} onClose={() => { setErrorInSignin(null); setErrorInSignup(null); setErrorInPasswords(null); setLoginOpen(false); setLoginEmail(""); setLoginPassword("");  setShowLoginPass(false) }} email={loginEmail} setEmail={setLoginEmail} password={loginPassword} setPassword={setLoginPassword} onDone={handleLogin} onRegisterClick={() => {  setLoginOpen(false); setOpen(true); setLoginEmail(""); setLoginPassword("") }} onCLickEye={() => setShowLoginPass(!showLoginPass)} showPass={showLoginPass} btnLoading={btnLoading} googleLogin={googleLogin} facebookLogin={responseFacebookLogin} appleLogin={responseAppleLogin} onForgetPass={() => { setLoginOpen(false); setLoginModalOpen(false); setLoginEmail(""); setLoginPassword(""); setRecoverOpen(true) }} />
                <RecoverPassword isOpen={recoverOpen} onError={errorInRecoverPassword} onClose={() => { setErrorInRecoverPassword(null); setRecoverOpen(false); setRecoveryEmail(""); setOtpType("verification") }} email={recoveryEmail} setEmail={setRecoveryEmail} btnLoading={btnLoading} sendRecoverLink={sendRecoveryEmail} />
                <ChangePassword isOpen={changePassOpen} onError={errorInChangePassword} onClose={() => { setErrorInChangePassword(null); setChangePassOpen(false); setNewPass(""); setConfirmPass(""); setRecoveryEmail(""); setOtpType("verification"); setShowPass(false) }} newPass={newPass} setNewPass={setNewPass} confirmPass={confirmPass} setConfirmPass={setConfirmPass} changePassword={handleChangePassword} btnLoading={btnLoading} showPass={showPass} onCLickEye={handleEyeClick} />

                {/* these different alert component are depends on the type of alert */}
                <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
                <Alert isOpen={!!confirmMsg} message={confirmMsg} onClose={() => { setConfirmMsg(null); setVerifyOpen(true);setNeedEmailInAlert(false) }} needEmail={needEmailinAlert} email={(otpType === "verification") ? (email === "") ? loginEmail : email : recoveryEmail}  />
                <Alert isOpen={!!resetPassConfirmMsg} message={resetPassConfirmMsg} onClose={() => { setResetPassConfirmMsg(null); setChangePassOpen(true) }} />
                <Alert isOpen={!!verificaionPendingAlert} message={verificaionPendingAlert} onClose={() => { setVerificaionPendingAlert(null); setVerifyOpen(true);setNeedEmailInAlert(false) }} email={(otpType === "verification") ? (email === "") ? loginEmail : email : recoveryEmail} needEmail={needEmailinAlert}/>
                <Alert isOpen={!!accountConfirmAlert} message={accountConfirmAlert} onClose={() => {setAccountConfirmAlert(null);setVerifyOpen(true);setNeedEmailInAlert(false)}} email={(otpType === "verification") ? (email === "") ? loginEmail : email : recoveryEmail} needEmail={needEmailinAlert}/>
                <DowngGradePlanAlertModel isOpen={!!downgradeMsg} message={downgradeMsg} type='submit' onConfirm={handleDownGradeTofreeTrial} onClose={() => setdowngradeMsg(null)} loading={loading1}/>

        </>
    )
}

export default Header