import React from 'react';
import Slider from 'react-slick';
import "./Section3.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Section3 = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    
                }
            }
        ],


    };

    const data = [
        {
            name: 'Todd A.',
            title: '“A+ service”'
        },
        {
            name: 'Peter C.',
            title: '“Thank you so much!”'
        },
        {
            name: 'Ashley.',
            title: '“Good service”'
        },
        {
            name: 'Mike C',
            title: '“Thank you !!”'
        },
    ];

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {data.map((item, i) => (
                    <div className="blog" key={i}>
                        <p className="text">{item.title}</p>
                        <p className="name">-{item.name}</p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Section3;
