import close_icon from "../../images/cross_icon.svg";
import "./password.css"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';

const PasswordModal = ({ isOpen, onClose, pass, setPass, showPass, onClickEye, email, onDone, btnLoading, onClickRegister, onError }) => {
  if (!isOpen) return null;
  return (

    <div className="passwaord-container">
      <div className="close-container">
        <img src={close_icon} alt="" onClick={onClose} />
      </div>
      <div className="text-container">
        <p className="heading">Welcome</p>
        <p className="sub-heading" style={{ color: "rgba(44, 44, 44, 1)" }}>
          Create account for <span style={{fontWeight:'bold'}}>{(email === "") ? "email@email.com" : email}</span> {" "}
        </p>
        <p className="sub-heading" style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline", cursor: "pointer" }} onClick={onClickRegister}>Register with a different email</p>
      </div>
      <div className="input-password-container">
        <label htmlFor="">Create Password</label>
        <div className="input_box">
          <input type={(showPass) ? "text" : "password"} value={pass} onChange={(e) => setPass(e.target.value)} />
          <span className="eye-icon" onClick={onClickEye}>{(showPass) ? <VisibilityIcon style={{opacity:"0.7"}}/> : <VisibilityOffIcon style={{opacity:"0.7"}}/>}</span>
        </div>
      </div>
      {onError &&
        <div className="errorMsg error-msg-box">
          {onError}
        </div>
      }
      <div className="create-btn">
        <button onClick={onDone} disabled={btnLoading}>{(btnLoading) ? <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> : "Create Account"}</button>
      </div>
      <p className="regulations">By creating an account, I agree to Jobweaver.ai's {" "}<a href="/terms-of-service" style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline" }}> Terms</a>  and <a href="/privacy-policy" style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline" }}> Privacy policy</a>
      {/* <a href="/cookie-policy" style={{ color: "rgba(48, 98, 76, 1)", textDecoration: "underline" }}>Cookie Policy.</a> */}
      </p>
    </div>

  );
};

export default PasswordModal;
