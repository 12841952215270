import "./Section5.css"
import { context } from '../../../store/context';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import axios from "axios"
import { CircularProgress } from '@mui/material';
import Alert from '../../../modals/AlertModal/Alert';
import DowngGradePlanAlertModel from '../../../modals/DownGradePlanAlert/DownGradePlanAlert';
import UpgradePlanAlertModel from '../../../modals/UpgradePlanAlertModel/UpgradePlanAlertModel';
import checkMark from '../../../images/checkmark.png'
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const REACT_APP_PREMIUM_PLAN_ID = process.env.REACT_APP_PREMIUM_PLAN_ID

const Section5 = () => {
  const { loginDetails, setLoginDetails, isUserHavingCard, setLoginModalOpen,setUserPlanDetails,  setUserHavingPlan } = useContext(context);
  const [loading1,setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [alertMsg, setAlertMsg] = useState(null)
  const [confirmMsg, setConfirmMsg] = useState(null)

  const [downgradeMsg, setdowngradeMsg] = useState(null);
  const [upgradeMsg, setupgradeMsg] = useState(null);
  

  const navigate = useNavigate();


  const handleSubscription = async (plan_id) => {
    if (!loginDetails.isLoggedIn) {
      return setLoginModalOpen(true)
    } else if (!isUserHavingCard) {
      navigate("/payment-details", {
        state: {
          buy_plan: true
        }
      })
    } else {
      setLoading2(true)
      const token = localStorage.getItem("token");
      await axios.post(`${REACT_APP_BASE_URL}/v1/subscribe`, { plan_id: plan_id }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        setUserHavingPlan(true)
        localStorage.setItem("free_trial", false);
        setLoginDetails({ ...loginDetails, free_trial: false })
        setLoading2(false)
        setAlertMsg('Your Plan Upgraded Successfully.');

        navigate("/step1")
      }).catch((error) => {
        console.log(error);
        if (error.response.data.message === "User already subscribed to this plan") {
        setAlertMsg('You already subscribed to this plan');

          localStorage.setItem("free_trial", false);
          setLoginDetails({ ...loginDetails, free_trial: false })
          setLoading2(false)
          navigate("/step1")
        } else {
          setAlertMsg(error.response.data.message);
        }
        setLoading2(false)
      })
    }
  }

  const handleFreeTrial = () => {
    if (!loginDetails.isLoggedIn) {
      return setLoginModalOpen(true)
    } else {
      navigate("/step1")
    }
  }

  const handleDownGradeTofreeTrial =async () =>{
      setLoading1(true)
      const token = localStorage.getItem("token");
      await axios.delete(`${REACT_APP_BASE_URL}/v1/un-subscribe`,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      }).then((res)=>{
        // console.log(res);
        localStorage.setItem("free_trial",true);
        setUserHavingPlan(false)
        setUserPlanDetails({})
        setLoginDetails({...loginDetails,free_trial:true})
        setdowngradeMsg('')
        setAlertMsg("You have been Unsubscribed from Premium Plan.")

        // navigate("/step1");
        setLoading1(false)
      }).catch((err)=>{
        // console.log(err);
        setdowngradeMsg('')
        setAlertMsg(err.response.data.message)
        setLoading1(false)
      })
  }

  const handlePlanConfirm = () => {
    if (!loginDetails.isLoggedIn) {
      return setLoginModalOpen(true)
    } else {
      // setConfirmMsg("Are you sure to buy a plan?");
      setupgradeMsg("Are you sure to buy a plan?");

    }
  }

  return (
    <>
      <div className="section5-top-container">
        <div className='plan-card'>
          <p className='card-heading'>Basic Membership</p>
          <div className='plan-heading'>
            <div className='text'>
              <img src={checkMark} width='30px' height='30px' alt="checkmark" />
              <div>
              <span>Access to Jobweaver.ai</span>
              <p className='subtext'>Enjoy one free search per day with a quick sign-up. </p>
              </div> 
            </div>

          </div>
          <div className='plan-heading'>
            <div className='text'>
            <img src={checkMark} width='30px' height='30px' alt="checkmark" />
               <span>Try Before You Buy</span>
            </div>
          </div>
          <div className='plan-heading'>
            <div className='text'>
            <img src={checkMark} width='30px' height='30px' alt="checkmark" />
              <span>Free</span>
            </div>

          </div>
          <div className="subscribe-btn">
            {
              (!loginDetails.isLoggedIn) ?
                <button onClick={handleFreeTrial} >Start Basic</button> :
                (loginDetails?.free_trial) ?
                  <button onClick={handleFreeTrial} disabled={loginDetails?.free_trial} >Start Basic</button> :

                  <button onClick={()=>setdowngradeMsg('downGrade')} disabled={loginDetails?.free_trial}  >Downgrade to Basic</button>
            }

          </div>
        </div>

        <div className='plan-card'>
          <p className='card-heading'>Paid Membership</p>
          <div className='plan-heading'>
            <div className='text'>
            <img src={checkMark} width='30px' height='30px' alt="checkmark" />
              <div>
               <span>Unlimited Searches</span>
              <p className='subtext'>Upgrade to unlimited searches with our premium service </p>
              </div>
            </div>

            
          </div>
          <div className='plan-heading'>
            <div className='text'>
            <img src={checkMark} width='30px' height='30px' alt="checkmark" />
              <span>Early Access to AI</span>
            </div>
          </div>
          <div className='plan-heading'>
            <div className='text'>
            <img src={checkMark} width='30px' height='30px' alt="checkmark" />
              <span>$5/month</span>
            </div>

          </div>
          <div className="subscribe-btn">
            {
              (!loginDetails.isLoggedIn) ?
                <button onClick={() => handleSubscription(REACT_APP_PREMIUM_PLAN_ID)} >
                  {(loading2) ?
                    <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> :
                    "Start Premium"}
                </button> :
                <button onClick={handlePlanConfirm} disabled={!loginDetails?.free_trial}>
                  {(loading2) ?
                    <CircularProgress sx={{ color: "darkgray", scale: "0.6" }} /> :
                    (!loginDetails?.free_trial) ? "Start Premium" : "Upgrade to Premium"}
                </button>
            }
          </div>
        </div>
      </div>
      {/* <div className='section5-bottom-container'>
            <div className='section5-buttton'>
                <button>Get started for free</button>
            </div>
        </div> */}
      <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
      {/* <Alert type="confirm" isOpen={!!confirmMsg} message={confirmMsg} onClose={() => setConfirmMsg(null)} onConfirm={() => { setConfirmMsg(null); handleSubscription(REACT_APP_PREMIUM_PLAN_ID) }} /> */}
      <DowngGradePlanAlertModel isOpen={!!downgradeMsg} message={downgradeMsg} type='submit' onConfirm={handleDownGradeTofreeTrial} onClose={() => setdowngradeMsg(null)} loading={loading1}/>
      <UpgradePlanAlertModel isOpen={!!upgradeMsg} message={upgradeMsg} type='submit' onConfirm={()=>{setConfirmMsg(null); handleSubscription(REACT_APP_PREMIUM_PLAN_ID) }} onClose={() => setupgradeMsg(null)} loading={loading2}/>

    </>
  )
}

export default Section5